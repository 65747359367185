import { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import Button from "~/themes/original/components/Button"
import { LockedIcon, PlayIcon } from "~/themes/original/components/Icon"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { query } from "~/utilities/trpc"

interface Props {
	courseId: number
	currentPageId: number | null
	firstUncompletedPageId: number | null
	setPage(pageId: number): void
}

const ChaptersList: React.FC<Props> = ({ courseId, currentPageId, firstUncompletedPageId, setPage }) => {
	const [listElement, setListElement] = useState<HTMLDivElement | null>(null)
	const [currentPageElement, setCurrentPageElement] = useState<
		(HTMLDivElement & HTMLButtonElement & HTMLAnchorElement) | null
	>(null)

	const content = query.course.listContent.useQuery({ courseId })

	useEffect(() => {
		if (listElement === null || currentPageElement === null) return

		listElement.scrollBy({
			behavior: "smooth",
			top:
				currentPageElement.offsetTop -
				(listElement.clientHeight - currentPageElement.clientHeight) / 2 -
				listElement.scrollTop,
		})
	}, [listElement, currentPageElement])

	if (content.error)
		return (
			<Container>
				<Header>Chapters</Header>
				<p>Failed to load content.</p>
			</Container>
		)

	if (content.data === undefined)
		return (
			<Container>
				<Header>Chapters</Header>
				<PartialLoadingPage />
			</Container>
		)

	const firstUncompletedPage =
		content.data
			.flatMap(chapter =>
				chapter.pages.map(page => ({ ...page, chapter: { id: chapter.id, order: chapter.order } })),
			)
			.find(page => page.id === firstUncompletedPageId) ?? null

	const isPageUnlocked = (chapter: { order: number }, page: { order: number }): boolean =>
		firstUncompletedPage === null ||
		firstUncompletedPage.chapter.order > chapter.order ||
		(firstUncompletedPage.chapter.order === chapter.order && firstUncompletedPage.order >= page.order)

	return (
		<Container>
			<Header>Chapters</Header>
			<List ref={setListElement}>
				{content.data.map(chapter => {
					const isActive = currentPageId !== null && chapter.pages.some(page => page.id === currentPageId)

					return (
						<Chapter key={chapter.id} $isActive={isActive}>
							<Title
								onClick={
									chapter.pages.length > 0 && isPageUnlocked(chapter, chapter.pages[0])
										? () => setPage(chapter.pages[0].id)
										: undefined
								}
							>
								{chapter.title}
							</Title>
							{isActive && (
								<Pages>
									{chapter.pages.map(page => {
										const isUnlocked = isPageUnlocked(chapter, page)
										const isCurrent = page.id === currentPageId

										return (
											<Page
												key={page.id}
												ref={isCurrent ? setCurrentPageElement : undefined}
												onClick={isUnlocked ? () => setPage(page.id) : undefined}
											>
												<PlayIconContainer>
													{isCurrent ? <PlayIcon /> : !isUnlocked ? <LockedIcon /> : null}
												</PlayIconContainer>
												<PageTitle>{page.title}</PageTitle>
											</Page>
										)
									})}
								</Pages>
							)}
						</Chapter>
					)
				})}
			</List>
		</Container>
	)
}

const Container = styled.div`
	grid-area: chapters;
	border: 1px solid #e8e8ed;
	border-radius: 8px;
	overflow: hidden;
	align-self: flex-start;

	@media (max-width: 999px) {
		display: none;
	}
`

const List = styled.div`
	max-height: 400px;
	overflow-y: auto;
	position: relative;
`

const Chapter = styled.div<{ $isActive?: boolean }>`
	background-color: white;
	padding: 16px 24px;

	&:not(:last-child) {
		border-bottom: 1px solid #e8e8ed;
	}

	${props =>
		props.$isActive &&
		css`
			background-color: #f9fafb;
		`}
`

const Header = styled(Chapter)`
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
	padding: 16px 24px;
`

const Title = styled(Button)`
	font-weight: 500;
	font-size: 18px;
	color: #302d3a;
`

const Pages = styled.div`
	margin-top: 8px;
`

const Page = styled(Button)`
	width: 100%;
	padding: 4px 0;
	display: flex;
	gap: 4px;
	align-items: center;
	border-top: 1px solid #e8e8ed;
`

const PlayIconContainer = styled.div`
	width: 18px;
	height: 18px;
`

const PageTitle = styled.h2`
	font-weight: normal;
	font-size: 14px;
	color: #302d3a;
`

export default ChaptersList
