import { useState } from "react"
import styled from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import withCommunityAuthCheck from "~/hocs/withCommunityAuthCheck"
import Button from "~/themes/school/components/Button"
import Layout from "~/themes/school/components/Layout"
import PageHeader, { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import Post from "~/themes/school/components/Post"
import CreatePostModal from "~/themes/school/pages/community/CommunityPage/CreatePostModal"
import { useTranslation } from "~/translations"
import { query } from "~/utilities/trpc"

const CommunityPage: React.FC = () => {
	const platform = usePlatform().platform
	const t = useTranslation()

	const [isCreatePostModalOpen, setCreatePostModalOpen] = useState(false)
	const [selectedChannelId, setSelectedChannelId] = useState<number | null>(null)

	const channels = query.community.listPersonalizedChannels.useQuery()
	const posts = query.community.listPersonalizedPostsByChannel.useQuery(selectedChannelId)

	const selectedChannel = channels.data?.find(channel => channel.id === selectedChannelId)

	return (
		<Layout>
			{selectedChannel && (
				<CreatePostModal
					channel={selectedChannel}
					isOpen={isCreatePostModalOpen}
					onClose={() => setCreatePostModalOpen(false)}
					onCreate={posts.refetch}
				/>
			)}
			<PageHeader>
				<PageTitle>{t("community.title")}</PageTitle>
				{selectedChannel?.userAccess === "post" && (
					<Button variant="primary" onClick={() => setCreatePostModalOpen(true)}>
						{t("community.post.create")}
					</Button>
				)}
			</PageHeader>
			{platform.content.multipleChannels &&
				(channels.error ? (
					<p>{t("failedToLoad")}</p>
				) : channels.data === undefined ? (
					<PartialLoadingPage />
				) : (
					<Channels>
						{channels.data.map(channel => (
							<Channel
								key={channel.id}
								variant={selectedChannelId === channel.id ? "primary" : "secondary"}
								onClick={() => setSelectedChannelId(channel.id)}
							>
								{channel.name}
							</Channel>
						))}
					</Channels>
				))}
			{posts.error ? (
				<p>{t("failedToLoad")}</p>
			) : posts.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Posts>
					{[...posts.data]
						.sort((a, b) => b.createDate.getTime() - a.createDate.getTime())
						.map(x => (
							<Post key={x.id} post={x} onChange={posts.refetch} />
						))}
				</Posts>
			)}
		</Layout>
	)
}

const Channels = styled.div`
	background-color: white;
	padding: 24px;
	display: flex;
	align-items: center;
	gap: 24px;
	margin-bottom: 24px;
`

const Channel = styled(Button)``

const Posts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`

export default withCommunityAuthCheck(CommunityPage)
