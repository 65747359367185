import styled from "styled-components"
import Values from "values.js"

import { toPriceString } from "@forento/shared/utilities/currency"
import { toDateString } from "@forento/shared/utilities/date"
import { toPaymentStatusString } from "@forento/shared/utilities/payment"

import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { lightTextColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

const BillingPage: React.FC = () => {
	const { data: transactions, error } = query.payment.listTransactions.useQuery()

	return (
		<Layout>
			<Title>Billing</Title>
			<Content>
				{error ? (
					<p>Failed to load transactions.</p>
				) : transactions === undefined ? (
					<PartialLoadingPage />
				) : (
					<TransactionsList>
						<TransactionsListHeader>
							<HeaderCell>Date</HeaderCell>
							<HeaderCell>Product</HeaderCell>
							<HeaderCell>Amount</HeaderCell>
							<HeaderCell>Status</HeaderCell>
							<HeaderCell>Receipt</HeaderCell>
						</TransactionsListHeader>
						{transactions.map(transaction => (
							<Transaction key={transaction.id}>
								<Cell>{toDateString(transaction.date)}</Cell>
								<Cell>{transaction.item?.title ?? "Other"}</Cell>
								<Cell>{toPriceString(transaction)}</Cell>
								<Cell>{toPaymentStatusString(transaction.status)}</Cell>
								{transaction.receipt.status === "available" ? (
									<Cell>
										<ReceiptButton onClick={transaction.receipt.url} newTab>
											Download
										</ReceiptButton>
									</Cell>
								) : transaction.receipt.status === "paypal" ? (
									<Cell>
										<ReceiptButton onClick="https://www.paypal.com" newTab>
											Visit PayPal
										</ReceiptButton>
									</Cell>
								) : (
									<Cell />
								)}
							</Transaction>
						))}
					</TransactionsList>
				)}
			</Content>
		</Layout>
	)
}

const Title = styled.h1`
	font-weight: 500;
	font-size: 36px;
	color: #151d48;
	margin-bottom: 24px;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 42px;

	@media (max-width: 999px) {
		padding: 0 16px;
	}
`

const TransactionsList = styled.div`
	border-radius: 8px;
	overflow: hidden;
`

const TransactionsListHeader = styled.div`
	background-color: #444a6d;
	padding: 16px 8px;
	display: flex;
	justify-content: space-between;
	gap: 8px;
`

const HeaderCell = styled.p`
	flex: 1 0 0;
	font-weight: 500;
	font-size: 14px;
	color: white;
	display: flex;
	justify-content: center;
`

const Transaction = styled.li`
	padding: 32px 8px;
	display: flex;
	justify-content: space-between;
	gap: 8px;

	border-left: 1px solid ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-right: 1px solid ${props => new Values(props.theme.primaryColor).tint(90).hexString()};

	&:last-child {
		border-bottom: 1px solid ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
		border-radius: 0 0 8px 8px;
	}

	&:nth-of-type(2n) {
		background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	}
`

const Cell = styled.p`
	flex: 1 0 0;
	font-size: 16px;
	color: ${lightTextColor};
	display: flex;
	justify-content: center;
`

const ReceiptButton = styled(Button)`
	color: ${props => props.theme.primaryColor};
`

export default BillingPage
