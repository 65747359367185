import { type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

import Thumbnail from "./Thumbnail"

const ExamResultPage: FC = () => {
	const resultId = parseNumber(useParams().resultId) ?? -1

	const { data: result, error } = query.exam.getCompletion.useQuery(resultId)

	if (result === null) return <Navigate to={routes.exam.index()} />

	return (
		<Layout>
			{error ? (
				<p>Failed to load exam result.</p>
			) : result === undefined ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<Details>
						<Title>Exam result</Title>
						<Description>{result.exam.name}</Description>
						<Score>
							{result.score.score}/{result.score.totalScore}
						</Score>
						<Button variant="primary" onClick={routes.exam.index()}>
							All exams
						</Button>
					</Details>
					<ThumbnailContainer>
						<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={16 / 12}>
							<Thumbnail />
						</AspectRatio>
					</ThumbnailContainer>
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 16px;
	display: flex;
	padding: 42px;
	gap: ${42 * 2}px;
	margin-bottom: 42px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 22px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
`

const Description = styled.p`
	font-size: 16px;
	color: ${lightTextColor};
`

const Score = styled.p`
	font-size: 24px;
	font-weight: bold;
`

const ThumbnailContainer = styled.div`
	flex: 1 0 0;
	border-radius: 8px;
	overflow: hidden;

	@media (max-width: 999px) {
		display: none;
	}
`

export default ExamResultPage
