import { type FC } from "react"

import { toRelativeDateString } from "@forento/shared/utilities/date"

import Button from "~/themes/school/components/Button"
import Card, {
	CardHeader,
	CardIcon,
	Cards,
	CardSticker,
	CardThumbnail,
	CardTitle,
} from "~/themes/school/components/Card"
import { CertificateIcon } from "~/themes/school/components/Icon"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useLocale, useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const CertificatesPage: FC = () => {
	const t = useTranslation()
	const locale = useLocale()

	const { data: certificates, error } = query.course.certificate.list.useQuery()

	return (
		<Layout>
			<PageTitle>{t("certificate.title")}</PageTitle>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : !certificates ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{certificates.map(certificate => (
						<Card key={certificate.id} as={Button} onClick={routes.certificate.detail(certificate.id)}>
							<CardHeader>
								<CardTitle>{certificate.course.title}</CardTitle>
								<CardSticker>{toRelativeDateString(certificate.createDate, locale)}</CardSticker>
							</CardHeader>
							{certificate.course.thumbnailFilePath ? (
								<CardThumbnail filePath={certificate.course.thumbnailFilePath} />
							) : (
								<CardIcon icon={CertificateIcon} />
							)}
						</Card>
					))}
				</Cards>
			)}
		</Layout>
	)
}

export default CertificatesPage
