import { type FC, useMemo, useState } from "react"

import Button from "~/themes/school/components/Button"
import Card, { CardHeader, CardSticker, CardText, CardTitle, Cards } from "~/themes/school/components/Card"
import ContentSearch from "~/themes/school/components/ContentSearch"
import Layout from "~/themes/school/components/Layout"
import PageHeader, { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const ExamsPage: FC = () => {
	const t = useTranslation()

	const [searchItems, setSearchItems] = useState<number[]>()

	const { data: exams, error } = query.exam.listPersonalized.useQuery()

	const contentSearchItems = useMemo(() => exams?.map(exam => ({ id: exam.id, value: exam.name })) ?? [], [exams])

	return (
		<Layout>
			<PageHeader>
				<PageTitle>{t("exam.title")}</PageTitle>
				<ContentSearch items={contentSearchItems} onItemsToShowChange={setSearchItems} />
			</PageHeader>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : exams === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{exams
						.filter(x => searchItems?.includes(x.id) ?? true)
						.sort((a, b) => (searchItems ? searchItems.indexOf(b.id) - searchItems.indexOf(a.id) : 0))
						.map(exam => (
							<Card key={exam.id} as={Button} onClick={routes.exam.detail(exam.id)}>
								<CardHeader>
									<CardTitle>{exam.name}</CardTitle>
									<CardSticker>
										{exam.userScore !== null
											? `${exam.userScore}/${exam.questions.length}`
											: t("exam.numberOfQuestions", { count: exam.questions.length })}
									</CardSticker>
								</CardHeader>
								{exam.shortDescription && <CardText>{exam.shortDescription}</CardText>}
							</Card>
						))}
				</Cards>
			)}
		</Layout>
	)
}

export default ExamsPage
