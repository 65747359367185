import { type FC, useLayoutEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import { useAlert } from "~/contexts/AlertContext"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

import Question from "./Question"

const StartExamPage: FC = () => {
	const examId = parseNumber(useParams().examId) ?? -1
	const alert = useAlert()
	const navigate = useNavigate()

	const { data: exam, error } = query.exam.getPersonalized.useQuery(examId)

	const [startDate] = useState(new Date())
	const [answers, setAnswers] = useState<Record<number, number[]>>({})

	useLayoutEffect(() => {
		setAnswers({})
	}, [exam?.questions])

	if (exam === null || exam?.userScore !== null) return <Navigate to={routes.exam.index()} />

	const isValid = exam?.questions.every(question => answers[question.id]?.length > 0) ?? false

	const handleSubmit = async () => {
		if (!isValid) return

		const dialog = await alert.confirm("Complete exam", "Are you sure you want to complete this exam?")
		if (!dialog.result) return

		try {
			const { resultId } = await trpc.exam.complete.mutate({
				id: examId,
				startDate,
				answers: Object.entries(answers).map(([questionId, answerIds]) => ({
					questionId: Number(questionId),
					answerIds,
				})),
			})
			dialog.close()
			navigate(routes.exam.result(resultId))
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Layout>
			<Container>
				<Title>{exam !== undefined ? exam.name : "..."}</Title>
				{error ? (
					<p>Failed to load exam.</p>
				) : exam === undefined ? (
					<PartialLoadingPage />
				) : (
					<>
						<Questions>
							{exam.questions.map((question, index) => (
								<Question
									key={question.id}
									question={question}
									index={index}
									selectedIds={answers[question.id] ?? []}
									setSelectedIds={value => {
										setAnswers({
											...answers,
											[question.id]: value,
										})
									}}
								/>
							))}
						</Questions>
						<ButtonRow>
							<Button variant="danger-bordered" onClick={() => setAnswers({})}>
								Clear
							</Button>
							<Button variant="primary" isDisabled={!isValid} onClick={handleSubmit}>
								Complete
							</Button>
						</ButtonRow>
					</>
				)}
			</Container>
		</Layout>
	)
}

const Container = styled.div`
	@media (max-width: 999px) {
		padding: 0 16px;
	}
`

const Title = styled.h1`
	margin-bottom: 16px;
`

const Questions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 16px;
`

const ButtonRow = styled.div`
	display: flex;
	gap: 16px;
`

export default StartExamPage
