import { type FC } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import Hero from "~/themes/school/components/Hero"
import { ArrowIcon } from "~/themes/school/components/Icon"
import Layout from "~/themes/school/components/Layout"
import { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

import Thumbnail from "./Thumbnail"

const ExamPage: FC = () => {
	const examId = parseNumber(useParams().examId) ?? -1
	const user = useUser().user
	const alert = useAlert()
	const navigate = useNavigate()
	const t = useTranslation()

	const { data: exam, error } = query.exam.getPersonalized.useQuery(examId)

	if (exam === null) return <Navigate to={routes.exam.index()} />

	const handleStart = async () => {
		if (exam === undefined) return

		if (user === null) {
			return navigate(`${routes.user.signup()}?next=${encodeURIComponent(location.pathname)}`)
		}

		const dialog = await alert.confirm(t("exam.start"), t("exam.startPrompt"))
		if (!dialog.result) return
		dialog.close()
		navigate(routes.exam.start(exam.id))
	}

	return (
		<Layout>
			<PageBreadcrumb path={[{ title: "Exams", link: routes.exam.index() }]} title={exam?.name ?? "..."} />
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : exam === undefined ? (
				<PartialLoadingPage />
			) : (
				<Hero
					thumbnail={<Thumbnail />}
					title={exam.name}
					htmlDescription={exam.longDescription?.html}
					callToAction={
						exam.userScore === null ? (
							<Button variant="primary" onClick={handleStart}>
								<CallToActionIcon as={ArrowIcon} />
								{t("exam.start")}
							</Button>
						) : (
							<ExamCompletedText>{t("exam.alreadyCompleted")}</ExamCompletedText>
						)
					}
				/>
			)}
		</Layout>
	)
}

const CallToActionIcon = styled.div`
	width: 16px;
	height: 16px;
`

const ExamCompletedText = styled.p`
	font-size: 14px;
	font-style: italic;
`

export default ExamPage
