import { type FC, type ReactNode, createContext, useContext, useMemo } from "react"

import { type PublicPlatform } from "@forento/shared/models/platform"

import { query } from "~/utilities/trpc"

type Value = { platform: PublicPlatform; apiVersion: string }

const PlatformContext = createContext<Value>({ platform: initialPlatform, apiVersion: CONFIG.apiVersion })

export const usePlatform = () => useContext(PlatformContext)

export const PlatformContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const { data: platform } = query.platform.getPublic.useQuery()

	const value = useMemo<Value>(
		() => ({
			platform: platform ? { ...platform, apiVersion: undefined } : initialPlatform,
			apiVersion: platform?.apiVersion ?? CONFIG.apiVersion,
		}),
		[platform],
	)

	return <PlatformContext.Provider value={value}>{children}</PlatformContext.Provider>
}

export const PlatformContextConsumer = PlatformContext.Consumer
