import { type FC } from "react"

import { toPriceString } from "@forento/shared/utilities/currency"

import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import Card, {
	CardFooter,
	CardHeader,
	CardSticker,
	CardText,
	CardThumbnail,
	CardTitle,
	Cards,
} from "~/themes/school/components/Card"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const MembershipsPage: FC = () => {
	const user = useUser()
	const t = useTranslation()

	const { data: memberships, error } = query.membership.list.useQuery()

	return (
		<Layout>
			<PageTitle>{t("membership.title")}</PageTitle>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : memberships === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{memberships.map(membership => {
						const isActive =
							user.user?.membership?.id === membership.id &&
							(user.user.membership.endDate === null || user.user.membership.endDate > new Date())

						return (
							<Card key={membership.id} as={Button} onClick={routes.membership.detail(membership.id)}>
								<CardHeader>
									<CardTitle>{membership.title}</CardTitle>
									{isActive && <CardSticker $isBold>{t("membership.active")}</CardSticker>}
								</CardHeader>
								<CardThumbnail filePath={membership.thumbnailFilePath} />
								<CardText>{membership.shortDescription}</CardText>
								<CardFooter>
									{(membership.prices.length === 1
										? [null, ...membership.prices]
										: membership.prices
									).map(price =>
										price ? (
											<CardSticker key={price.id} $isBold>
												{toPriceString(price)}/{price.paymentInterval}
											</CardSticker>
										) : (
											<CardSticker key="placeholder" />
										),
									)}
								</CardFooter>
							</Card>
						)
					})}
				</Cards>
			)}
		</Layout>
	)
}

export default MembershipsPage
