import { type FC, useState } from "react"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"

import { useUser } from "~/contexts/UserContext"
import { SubmitButton } from "~/themes/school/components/Button"
import Comment from "~/themes/school/components/Comment"
import InputField from "~/themes/school/components/InputField"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { lightTextColor } from "~/themes/school/styles"
import { useTranslation } from "~/translations"
import trpc, { query } from "~/utilities/trpc"

type Props = { courseId: number; commentsUnderReview: number; pageId: number | null; reloadCourse(): Promise<unknown> }

const CourseComments: FC<Props> = ({ courseId, commentsUnderReview, pageId, reloadCourse }) => {
	const user = useUser()
	const t = useTranslation()

	const [newComment, setNewComment] = useState("")
	const [newReplies, setNewReplies] = useState<Record<number, string>>("")

	const [isSubmittingNewComment, setSubmittingNewComment] = useState(false)
	const [submittingReplyIds, setSubmittingReplyIds] = useState<number[]>([])

	const comments = query.course.listComments.useQuery({ courseId })

	async function handleCreateComment() {
		if (newComment.trim() === "") return
		setSubmittingNewComment(true)
		try {
			await trpc.course.createComment.mutate({ courseId, pageId, text: newComment })
			setNewComment("")
			await Promise.all([comments.refetch(), reloadCourse()])
		} finally {
			setSubmittingNewComment(false)
		}
	}

	async function handleCreateReply(commentId: number) {
		const text = newReplies[commentId]?.trim() ?? ""
		if (text === "") return
		setSubmittingReplyIds(current => [...current, commentId])
		try {
			await trpc.course.createCommentReply.mutate({ commentId, pageId, text })
			setNewReplies(current => ({ ...current, [commentId]: "" }))
			await Promise.all([comments.refetch(), reloadCourse()])
		} finally {
			setSubmittingReplyIds(current => current.filter(id => id !== commentId))
		}
	}

	async function handleDeleteComment(commentId: number) {
		await trpc.course.deleteComment.mutate(commentId)
		await comments.refetch()
	}

	async function handleDeleteReply(replyId: number) {
		await trpc.course.deleteCommentReply.mutate(replyId)
		await comments.refetch()
	}

	return (
		<Container>
			{comments.error ? (
				<p>{t("failedToLoad")}</p>
			) : comments.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					{user.user && (
						<NewCommentContainer onSubmit={handleCreateComment}>
							<InputField
								value={newComment}
								onChange={setNewComment}
								placeholder={t("community.post.writeComment")}
							/>
							<SubmitButton variant="primary" isLoading={isSubmittingNewComment}>
								{t("community.post.postComment")}
							</SubmitButton>
						</NewCommentContainer>
					)}
					{commentsUnderReview > 0 && (
						<p>{t("course.commentsUnderReview", { count: commentsUnderReview })}</p>
					)}
					{comments.data.map(comment => (
						<div key={comment.id}>
							<Comment
								comment={{
									...comment,
									sticker:
										comment.page !== null
											? `${comment.page.chapter.title} &gt; ${comment.page.title}`
											: undefined,
								}}
								onDelete={() => handleDeleteComment(comment.id)}
							/>
							<Replies>
								{comment.replies.map(reply => (
									<Comment
										key={reply.id}
										comment={{
											...reply,
											sticker:
												reply.page !== null
													? `${reply.page.chapter.title} &gt; ${reply.page.title}`
													: undefined,
										}}
										onDelete={() => handleDeleteReply(reply.id)}
									/>
								))}
								{user.user && (
									<NewCommentContainer onSubmit={() => handleCreateReply(comment.id)}>
										<InputField
											value={newReplies[comment.id] ?? ""}
											onChange={value => setNewReplies(c => ({ ...c, [comment.id]: value }))}
											placeholder={t("community.post.writeReply")}
										/>
										<SubmitButton
											variant="primary"
											isLoading={submittingReplyIds.includes(comment.id)}
										>
											{t("community.post.reply")}
										</SubmitButton>
									</NewCommentContainer>
								)}
							</Replies>
						</div>
					))}
				</>
			)}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const NewCommentContainer = styled(Form)`
	display: flex;
	gap: 8px;

	@media (max-width: 500px) {
		flex-direction: column;
	}
`

const Replies = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-left: 16px;
	border-left: 1px solid ${lightTextColor};

	&:not(:empty) {
		margin-top: 8px;
	}

	@media (min-width: 1000px) {
		margin-left: 32px;
		padding: 0;
		border-left: none;
	}
`

export default CourseComments
