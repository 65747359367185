import { useParams } from "react-router"
import styled, { css } from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { lightTextColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

import ConversationListItem from "./ConversationListItem"
import DetailedConversation from "./DetailedConversation"

const ConversationsPage: React.FC = () => {
	const otherProfileId = parseNumber(useParams().otherProfileId ?? "")

	const conversations = query.community.listConversations.useQuery()
	const currentConversation = query.community.getConversation.useQuery(otherProfileId ?? -1)

	return (
		<Layout>
			{conversations.error || currentConversation.error ? (
				<p>Failed to load conversations.</p>
			) : !conversations.data ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<Conversations $hasSelectedConversation={Boolean(currentConversation.data)}>
						{conversations.data.length > 0 ? (
							[...conversations.data]
								.sort((a, b) => b.lastMessage.sendDate.getTime() - a.lastMessage.sendDate.getTime())
								.map((conversation, index) => (
									<ConversationListItem key={index} conversation={conversation} />
								))
						) : (
							<NoConversations>No conversations</NoConversations>
						)}
					</Conversations>
					{currentConversation.data && (
						<DetailedConversation
							conversation={currentConversation.data}
							reload={async () => {
								await Promise.all([conversations.refetch(), currentConversation.refetch()])
							}}
						/>
					)}
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	flex: 1;
	height: 100%;
	display: flex;
	align-items: flex-start;
	gap: 42px;
`

const Conversations = styled.div<{ $hasSelectedConversation: boolean }>`
	flex: 1 0 0;
	background-color: white;
	border-radius: 8px;
	padding: ${24 - 16}px 24px;

	${props =>
		props.$hasSelectedConversation &&
		css`
			@media (max-width: 999px) {
				display: none;
			}
		`}
`

const NoConversations = styled.p`
	font-style: italic;
	color: ${lightTextColor};
`

export default ConversationsPage
