import { type FC, useMemo, useState } from "react"
import { Link } from "react-router"
import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { toPriceString } from "@forento/shared/utilities/currency"

import Card, { CardText, CardTitle, Cards } from "~/themes/school/components/Card"
import ContentSearch from "~/themes/school/components/ContentSearch"
import Layout from "~/themes/school/components/Layout"
import PageHeader, { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { backgroundColor } from "~/themes/school/styles"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const DownloadablesPage: FC = () => {
	const t = useTranslation()

	const [searchItems, setSearchItems] = useState<number[]>()

	const { data: downloadables, error } = query.downloadable.listPersonalized.useQuery()

	const contentSearchItems = useMemo(
		() => downloadables?.map(downloadables => ({ id: downloadables.id, value: downloadables.title })) ?? [],
		[downloadables],
	)

	return (
		<Layout>
			<PageHeader>
				<PageTitle>{t("downloadable.title")}</PageTitle>
				<ContentSearch items={contentSearchItems} onItemsToShowChange={setSearchItems} />
			</PageHeader>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : downloadables === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{downloadables
						.filter(x => searchItems?.includes(x.id) ?? true)
						.sort((a, b) => (searchItems ? searchItems.indexOf(b.id) - searchItems.indexOf(a.id) : 0))
						.map(downloadable => (
							<Card key={downloadable.id} as={Link} to={routes.downloadable.detail(downloadable.id)}>
								<CardTitle>{downloadable.title}</CardTitle>
								<AspectRatioContainer>
									<StyledAspectRatio aspectRatio={16 / 9} width={{ value: 100, unit: "percent" }}>
										<ThumbnailContainer>
											{downloadable.thumbnailFilePath !== null && (
												<Thumbnail src={downloadable.thumbnailFilePath} />
											)}
											<Price>
												{downloadable.price
													? toPriceString(downloadable.price)
													: downloadable.membershipIds.length > 0
														? t("membership.title")
														: t("product.priceFree")}
											</Price>
										</ThumbnailContainer>
									</StyledAspectRatio>
								</AspectRatioContainer>
								{downloadable.shortDescription && <CardText>{downloadable.shortDescription}</CardText>}
							</Card>
						))}
				</Cards>
			)}
		</Layout>
	)
}

const AspectRatioContainer = styled.div`
	margin: 0 42px;
`

const StyledAspectRatio = styled(AspectRatio)`
	background-color: ${backgroundColor};
	overflow: hidden;
`

const ThumbnailContainer = styled.div`
	position: relative;
`

const Thumbnail = styled.img.attrs({ alt: "Downloadable thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const Price = styled.p`
	position: absolute;
	top: 16px;
	right: 16px;
	font-weight: bold;
	font-size: 16px;
	color: #ffa412;
	background-color: white;
	padding: 4px 8px;
`

export default DownloadablesPage
