import { type FC } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"

import { type EventResponse } from "@forento/shared/models/event"
import { parseNumber } from "@forento/shared/utilities/number"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import Hero from "~/themes/school/components/Hero"
import { CloseIcon } from "~/themes/school/components/Icon"
import { ArrowIcon, CheckIcon } from "~/themes/school/components/Icon"
import Layout from "~/themes/school/components/Layout"
import { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import PurchaseItem from "~/themes/school/components/PurchaseItem"
import AddToCalendar from "~/themes/school/pages/event/EventPage/AddToCalendar"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

const EventPage: FC = () => {
	const eventId = parseNumber(useParams().eventId ?? "")
	const user = useUser()
	const navigate = useNavigate()
	const t = useTranslation()

	const { data: event, error, refetch } = query.event.getPersonalized.useQuery(eventId ?? -1)

	if (event === null) return <Navigate to={routes.event.index()} />

	async function handleRespond(response: EventResponse | null) {
		if (eventId === null) return
		if (user.user === null) {
			return navigate(`${routes.user.signup()}?next=${encodeURIComponent(location.pathname)}`)
		}

		await trpc.event.setResponse.mutate({ eventId, response })
		await refetch()
	}

	return (
		<PurchaseSuccessModalContextProvider
			content={{
				type: "event",
				isUnlocked: event?.isUnlocked ?? false,
				payPalOrderId: event?.isUnlocked && event.order?.type === "paypal" ? event.order.id : null,
			}}
		>
			<Layout>
				<PageBreadcrumb
					path={[{ title: "Events", link: routes.event.index() }]}
					title={event?.title ?? "..."}
				/>
				{error ? (
					<p>{t("failedToLoad")}</p>
				) : event === undefined ? (
					<PartialLoadingPage />
				) : (
					<Hero
						title={event.title}
						thumbnail={event.thumbnailFilePath && <Thumbnail src={event.thumbnailFilePath} />}
						htmlDescription={event.longDescription ? event.longDescription.html : undefined}
						callToAction={
							!event.isUnlocked ? (
								<PurchaseItem
									item={{ id: event.id, price: event.price, type: "event" }}
									onPurchase={refetch}
								/>
							) : event.response === "attending" ? (
								<>
									<Actions>
										{event.videoCallLink !== null && (
											<Button variant="primary" onClick={event.videoCallLink} newTab>
												<ActionIcon as={ArrowIcon} />
												{t("event.enter")}
											</Button>
										)}
										<Button key="cancel" variant="danger" onClick={() => handleRespond(null)}>
											<ActionIcon as={CloseIcon} />
											{t("event.cancelAttendance")}
										</Button>
									</Actions>
									<AddToCalendar event={event} />
								</>
							) : (
								<Actions>
									<Button key="attend" variant="primary" onClick={() => handleRespond("attending")}>
										<ActionIcon as={ArrowIcon} />
										{t("event.attend")}
									</Button>
									{event.response !== "interested" && (
										<Button
											key="interested"
											variant="secondary"
											onClick={() => handleRespond("interested")}
										>
											<ActionIcon as={CheckIcon} />
											{t("event.interested")}
										</Button>
									)}
									{event.response !== "not-interested" && (
										<Button
											key="not-interested"
											variant="secondary"
											onClick={() => handleRespond("not-interested")}
										>
											<ActionIcon as={CloseIcon} />
											{t("event.notInterested")}
										</Button>
									)}
								</Actions>
							)
						}
					/>
				)}
			</Layout>
		</PurchaseSuccessModalContextProvider>
	)
}

const Thumbnail = styled.img.attrs({ alt: "Event thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const ActionIcon = styled.div`
	width: 16px;
	height: 16px;
`

const Actions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export default EventPage
