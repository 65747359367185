import { useRef, type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import { parseNumber } from "@forento/shared/utilities/number"

import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { downloadElement } from "~/utilities/element"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const CertificatePage: FC = () => {
	const certificateId = parseNumber(useParams().certificateId)
	const user = useUser().user!
	const { platform } = usePlatform()

	const downloadRef = useRef<HTMLDivElement>(null)

	const { data: certificate, error } = query.course.certificate.get.useQuery(certificateId ?? -1)

	if (certificate === null) return <Navigate to={routes.certificate.index()} />

	return (
		<Layout>
			{error ? (
				<p>Failed to load certificate.</p>
			) : !certificate ? (
				<PartialLoadingPage />
			) : (
				<>
					<Container ref={downloadRef}>
						<Content>
							<Title>Certificate of completion</Title>
							<Details>
								<Text>This is to certify that</Text>
								<Value>
									{user.firstName} {user.lastName}
								</Value>
								<Text>has successfully completed the course</Text>
								<Value>{certificate.course.title}</Value>
								<Text>
									on{" "}
									{new Intl.DateTimeFormat("en-US", { dateStyle: "long" }).format(
										certificate.createDate,
									)}
								</Text>
							</Details>
							{platform.logo &&
								(platform.logo.isIcon ? (
									<Icon alt="Logo" src={platform.logo.filePath} />
								) : (
									<Logo alt="Logo" src={platform.logo.filePath} />
								))}
						</Content>
					</Container>
					<DownloadButton
						onClick={async () => {
							if (!certificate || !downloadRef.current) return
							await downloadElement(downloadRef.current)
						}}
					>
						Download
					</DownloadButton>
				</>
			)}
		</Layout>
	)
}

const Container = styled.div`
	max-width: 800px;
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	padding: 24px;
	margin-bottom: 42px;
`

const Content = styled.div`
	border: 10px solid ${props => props.theme.primaryColor};
	padding: 64px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;
`

const Title = styled.h1`
	font-size: 32px;
	text-transform: uppercase;
	text-align: center;
`

const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
`

const Text = styled.p`
	font-size: 20px;
	text-align: center;
`

const Value = styled.p`
	font-size: 28px;
	font-weight: 600;
	text-align: center;
`

const Icon = styled.img`
	width: 100px;
	height: 100px;
	object-fit: contain;
`

const Logo = styled.img`
	width: 500px;
	height: 50px;
	object-fit: contain;
`

const DownloadButton = styled(Button).attrs({ variant: "primary" })`
	align-self: flex-start;
`

export default CertificatePage
