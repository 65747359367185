import { type FC, useState } from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"
import { parseNumber } from "@forento/shared/utilities/number"

import { useAlert } from "~/contexts/AlertContext"
import { SubmitButton } from "~/themes/school/components/Button"
import Comment from "~/themes/school/components/Comment"
import InputField from "~/themes/school/components/InputField"
import Layout from "~/themes/school/components/Layout"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import Post from "~/themes/school/components/Post"
import { ControlledTabs } from "~/themes/school/components/Tabs"
import Like from "~/themes/school/pages/community/CommunityPostPage/Like"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

const CommunityPostPage: FC = () => {
	const postId = parseNumber(useParams().postId ?? "") ?? -1
	const alert = useAlert()
	const t = useTranslation()

	const post = query.community.getPersonalizedPost.useQuery(postId)
	const reactions = query.community.listPostReactions.useQuery(postId)
	const comments = query.community.listPostComments.useQuery(postId)

	const [text, setText] = useState("")
	const [isSubmittingComment, setSubmittingComment] = useState(false)

	const handleSubmitComment = async () => {
		setSubmittingComment(true)
		try {
			await trpc.community.createPostComment.mutate({ postId, text })
			setText("")
			await comments.refetch()
		} finally {
			setSubmittingComment(false)
		}
	}

	const handleDeleteComment = async (commentId: number) => {
		const dialog = await alert.confirm(t("community.post.deleteComment"), t("community.post.deleteCommentPrompt"))
		if (!dialog.result) return

		await trpc.community.deletePostComment.mutate(commentId)
		await comments.refetch()

		dialog.close()
	}

	return (
		<Layout onBackButtonClick={routes.community.index()}>
			{post.error ? (
				<p>{t("failedToLoad")}</p>
			) : post.data === undefined ? (
				<PartialLoadingPage />
			) : post.data === null ? (
				<p>{t("community.post.notFound")}</p>
			) : (
				<>
					<Post
						post={{ ...post.data, commentsCount: comments.data?.length ?? post.data.commentsCount }}
						onChange={() => Promise.all([post.refetch(), reactions.refetch()])}
					/>
					<Reactions>
						<ControlledTabs
							tabs={[
								{
									title: t("community.comments"),
									content: (
										<>
											{(post.data.channel.userAccess === "post" ||
												post.data.channel.userAccess === "comment") && (
												<SubmitComment onSubmit={handleSubmitComment}>
													<SubmitCommentInput
														placeholder={t("community.post.writeComment")}
														value={text}
														onChange={setText}
													/>
													<SubmitButton variant="primary" isLoading={isSubmittingComment}>
														{t("community.post.postComment")}
													</SubmitButton>
												</SubmitComment>
											)}
											<CommentsList>
												{comments.error ? (
													t("failedToLoad")
												) : comments.data === undefined ? (
													<PartialLoadingPage />
												) : (
													comments.data.map(comment => (
														<Comment
															key={comment.id}
															comment={comment}
															onDelete={() => handleDeleteComment(comment.id)}
														/>
													))
												)}
											</CommentsList>
										</>
									),
								},
								{
									title: t("community.reactions"),
									content: (
										<LikesList>
											{reactions.error ? (
												<p>{t("failedToLoad")}</p>
											) : reactions.data === undefined ? (
												<PartialLoadingPage />
											) : (
												reactions.data.map(reaction => (
													<Like key={reaction.profile.id} profile={reaction.profile} />
												))
											)}
										</LikesList>
									),
								},
							]}
						/>
					</Reactions>
				</>
			)}
		</Layout>
	)
}

const Reactions = styled.div`
	max-width: 1400px;
	background-color: #ffffff;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 24px;
	margin-top: 24px;

	@media (min-width: 1000px) {
		padding: 48px 86px;
	}
`

const SubmitComment = styled(Form)`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 22px;
	flex-direction: column;
	margin-bottom: 16px;

	@media (min-width: 600px) {
		flex-direction: row;
	}
`

const SubmitCommentInput = styled(InputField)`
	@media (max-width: 599px) {
		width: 100%;
	}
`

const CommentsList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const LikesList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export default CommunityPostPage
