import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import type React from "react"
import { type ComponentType, type FC, type ReactNode, useEffect, useLayoutEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { BrowserRouter, Navigate, Route, Routes, useLocation, useSearchParams } from "react-router"
import { createGlobalStyle, ThemeProvider } from "styled-components"

import "@forento/shared/assets/lexical.css"
import { type Theme } from "@forento/shared/models/platform"
import { combineContextProviders } from "@forento/shared/utilities/component"

import appRoutes from "~/appRoutes"
import { AlertContextProvider } from "~/contexts/AlertContext"
import { PlatformContextProvider, usePlatform } from "~/contexts/PlatformContext"
import { ThemeComponentProvider } from "~/contexts/ThemeComponentContext"
import { UserContextProvider, useUser } from "~/contexts/UserContext"
import { Styles as SchoolStyles } from "~/themes/school/components/Layout"
import "~/translations"
import { changeLanguage } from "~/translations"
import routes from "~/utilities/routes"
import trpc, { query, queryTrpcClient } from "~/utilities/trpc"

const GlobalStyle = createGlobalStyle`
	*, h1, h2, h3, h4, h5, h6, p {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		line-height: 1.42857;
	}
`

const AppThemeProvider: FC<{ children: ReactNode }> = ({ children }) => (
	<ThemeProvider theme={{ primaryColor: usePlatform().platform.primaryColor.value }}>{children}</ThemeProvider>
)

const AppContextProviders = combineContextProviders([
	HelmetProvider,
	BrowserRouter,
	UserContextProvider,
	PlatformContextProvider,
	AppThemeProvider,
	ThemeComponentProvider,
	AlertContextProvider,
])

const App: FC = () => {
	const [queryClient] = useState(() => new QueryClient())

	return (
		<query.Provider client={queryTrpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				<AppContextProviders>
					{!CONFIG.isDevelopment && (
						<Helmet>
							<script
								defer
								data-domain="platform.forento.io"
								src="/plausible/script.js"
								data-api="/plausible/event"
							/>
						</Helmet>
					)}
					<GlobalStyle />
					<Routes>
						{appRoutes.map(route => (
							<Route key={route.path} path={route.path} element={<AppRoute route={route} />} />
						))}
					</Routes>
				</AppContextProviders>
			</QueryClientProvider>
		</query.Provider>
	)
}

export default App

function getStylesByTheme(theme: Theme): ComponentType | null {
	switch (theme) {
		case 1:
			return null
		case 2:
			return SchoolStyles
	}
}

const AppRoute: FC<{ route: (typeof appRoutes)[number] }> = ({ route }) => {
	const platform = usePlatform().platform
	const theme = platform?.theme ?? 2
	const Page = route.page[theme]
	const Styles = getStylesByTheme(theme)

	useLayoutEffect(() => {
		changeLanguage(platform?.language ?? "en")
	}, [platform?.language])

	return (
		<Content
			path={route.path}
			isRouteAuthorized={route.auth ?? false}
			isCommunityProfileRequired={route.community ?? false}
		>
			{Styles && <Styles />}
			<Page />
		</Content>
	)
}

const Content: React.FC<{
	path?: string
	isRouteAuthorized: boolean
	isCommunityProfileRequired: boolean
	children: ReactNode
}> = ({ path, isRouteAuthorized, isCommunityProfileRequired, children }) => {
	const location = useLocation()
	const platform = usePlatform()
	const user = useUser()
	const [searchParams] = useSearchParams()

	useEffect(() => {
		window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
	}, [location.pathname])

	useEffect(() => {
		if (user.user === null || CONFIG.isDevelopment) return

		const timers: NodeJS.Timeout[] = []
		let activeDuration = 0
		let backgroundDuration = 0
		trpc.activity.createSession.mutate().then(session => {
			timers.push(
				setInterval(() => {
					if (window.document.hidden) backgroundDuration++
					else activeDuration++
				}, 1_000),
			)
			timers.push(
				setInterval(() => {
					trpc.activity.updateSession
						.mutate({ id: session.id, activeDuration, backgroundDuration })
						.catch(() => {})
				}, 10_000),
			)
		})

		return () => {
			timers.forEach(timer => clearInterval(timer))
		}
	}, [user.user])

	if (isRouteAuthorized && !user.user) {
		const prefilledEmail = searchParams.get("email") ?? undefined
		const params = new URLSearchParams({
			...(path !== undefined ? { next: location.pathname } : {}),
			...(prefilledEmail !== undefined ? { email: prefilledEmail } : {}),
		})
		const redirect =
			prefilledEmail !== undefined ? `${routes.user.signin()}?${params}` : `${routes.user.signup()}?${params}`

		return <Navigate to={redirect} replace />
	}

	if (isCommunityProfileRequired && user.user && user.user.communityProfileId === null)
		return (
			<Navigate
				to={`${routes.community.create()}${
					path !== undefined ? `?next=${encodeURIComponent(location.pathname)}` : ""
				}`}
				replace
			/>
		)

	if (location.pathname === "/") return <Navigate to="/courses" />

	const headItems: { element: string; [key: string]: string }[] = [
		platform.platform !== undefined && [
			{ element: "title", children: platform.platform.title ?? platform.platform.name },
			{ element: "meta", name: "og:title", content: platform.platform.title ?? platform.platform.name },
		],
		platform.platform.description != null && [
			{ element: "meta", name: "description", content: platform.platform.description },
			{ element: "meta", name: "og:description", content: platform.platform.description },
		],
		platform.platform.thumbnailFilePath != null && [
			{ element: "meta", content: platform.platform.thumbnailFilePath },
		],
	]
		.filter((item): item is [] => typeof item !== "boolean")
		.flatMap(value => value)

	return (
		<>
			<Helmet>
				{headItems.map(({ element: Element, ...rest }, index) => (
					<Element key={index} {...rest} />
				))}
			</Helmet>
			{children}
		</>
	)
}
