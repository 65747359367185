import { type ReactNode, useState } from "react"
import { Helmet } from "react-helmet-async"
import styled, { createGlobalStyle, css } from "styled-components"

import CookieConsent from "~/themes/school/components/CookieConsent"
import { BackIcon, CloseIcon, MenuIcon } from "~/themes/school/components/Icon"
import { backgroundColor } from "~/themes/school/styles"

import Footer from "./Footer"
import MobileHeader from "./MobileHeader"
import NavigationMenu from "./NavigationMenu"
import Sidebar from "./Sidebar"

interface Props {
	onBackButtonClick?: string | (() => void)
	fillHeight?: boolean
	className?: string
	children?: ReactNode
}

export const Styles = createGlobalStyle`
	body {
		font-family: "Work Sans", sans-serif;
	}
`

const Layout: React.FC<Props> = ({ onBackButtonClick, fillHeight = false, className, children }) => {
	const [isMenuOpen, setMenuOpen] = useState(false)

	return (
		<Wrapper>
			<Helmet>
				<link
					rel="stylesheet"
					href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&family=Work+Sans:wght@400;500;600;700&display=swap"
				/>
			</Helmet>
			<CookieConsent />
			<Container>
				<Sidebar />
				<MobileHeader
					leftAction={
						onBackButtonClick === undefined
							? {
									icon: isMenuOpen ? CloseIcon : MenuIcon,
									onClick: () => setMenuOpen(current => !current),
								}
							: { icon: BackIcon, onClick: onBackButtonClick }
					}
				/>
				<Content $fillHeight={fillHeight} className={className}>
					{children}
				</Content>
				<NavigationMenu isOpen={isMenuOpen} />
				<Footer />
			</Container>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	width: 100%;
	background-color: ${backgroundColor};
	display: flex;
	justify-content: center;
`

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;

	@media (min-width: 1000px) {
		margin-left: 250px;
	}
`

const Content = styled.div<{ $fillHeight: boolean }>`
	padding: 24px;
	display: flex;
	flex-direction: column;

	${props =>
		props.$fillHeight &&
		css`
			flex: 1;
		`}

	@media (min-width: 1000px) {
		padding: 42px;
	}
`

export default Layout
