import { useState } from "react"

import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import { SubmitButton } from "~/themes/original/components/Button"
import InputField from "~/themes/original/components/InputField"
import trpc from "~/utilities/trpc"

import Group from "./Group"

const DeleteAccount: React.FC = () => {
	const user = useUser()
	const alert = useAlert()

	const [password, setPassword] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSubmit = async () => {
		if (password.length === 0) return

		const dialog = await alert.confirm(
			"Delete account",
			"Are you sure you want to delete your account? This action is irreversible.",
		)
		if (!dialog.result) return

		setSubmitting(true)
		try {
			const response = await trpc.user.delete.mutate({ password })
			if (response.status === "invalid-password") {
				await alert.show("Error", "Invalid password.")
				return
			}

			await user.reload()
			dialog.close()
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Group title="Delete account" onSubmit={handleSubmit}>
			<InputField
				inputType="password"
				label="Current password"
				value={password}
				onChange={setPassword}
				autoComplete="current-password"
			/>
			<SubmitButton variant="danger-bordered" isDisabled={password.length === 0} isLoading={isSubmitting}>
				Delete account
			</SubmitButton>
		</Group>
	)
}

export default DeleteAccount
