import { type FC, useState } from "react"
import styled from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import withCommunityAuthCheck from "~/hocs/withCommunityAuthCheck"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import Post from "~/themes/original/components/Post"
import { query } from "~/utilities/trpc"

import CreatePost from "./CreatePost"

const CommunityPage: FC = () => {
	const platform = usePlatform().platform
	const [selectedChannelId, setSelectedChannelId] = useState<number | null>(null)

	const channels = query.community.listPersonalizedChannels.useQuery()
	const posts = query.community.listPersonalizedPostsByChannel.useQuery(selectedChannelId)

	const selectedChannel = channels.data?.find(channel => channel.id === selectedChannelId)

	return (
		<Layout>
			{platform.content.multipleChannels &&
				(channels.error ? (
					<p>Failed to load channels.</p>
				) : channels.data === undefined ? (
					<PartialLoadingPage />
				) : (
					<Channels>
						{channels.data.map(channel => (
							<Button
								key={channel.id}
								variant={selectedChannelId === channel.id ? "primary" : "secondary"}
								onClick={() => setSelectedChannelId(channel.id)}
							>
								{channel.name}
							</Button>
						))}
					</Channels>
				))}
			{selectedChannel?.userAccess === "post" && (
				<CreatePost channel={selectedChannel} onPostCreated={posts.refetch} />
			)}
			{posts.error ? (
				<p>Failed to load posts.</p>
			) : posts.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Posts>
					{[...posts.data]
						.sort((a, b) => b.createDate.getTime() - a.createDate.getTime())
						.map(x => (
							<Post key={x.id} post={x} onChange={posts.refetch} />
						))}
				</Posts>
			)}
		</Layout>
	)
}

const Channels = styled.div`
	background-color: #ffffff;
	border-radius: 8px;
	padding: 16px 24px;
	display: flex;
	gap: 16px;
	align-items: center;
	margin-bottom: 32px;
`

const Posts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

export default withCommunityAuthCheck(CommunityPage)
