import { useState } from "react"
import styled from "styled-components"

import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import { SubmitButton } from "~/themes/school/components/Button"
import InputField from "~/themes/school/components/InputField"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import { query } from "~/utilities/trpc"

import Group from "./Group"
import ProfileAvatar from "./ProfileAvatar"

const SettingsPage: React.FC = () => {
	const user = useUser()
	const alert = useAlert()

	const [firstName, setFirstName] = useState(user.user!.firstName)
	const [lastName, setLastName] = useState(user.user!.lastName)
	const [currentPassword, setCurrentPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
	const [deleteAccountPassword, setDeleteAccountPassword] = useState("")

	const personalInfo = query.user.update.useMutation({
		async onSuccess() {
			await user.reload()
			await alert.show("Success", "Personal info saved successfully!")
		},
		async onError(error) {
			console.error(error)
			await alert.show("Error", "Failed to save personal info. Please try again later.")
		},
	})
	const changePassword = query.user.setPassword.useMutation({
		async onSuccess({ status }) {
			if (status === "invalid-current") {
				await alert.show("Error", "Incorrect current password.")
				return
			}

			await user.reload()

			setCurrentPassword("")
			setNewPassword("")
			setNewPasswordConfirmation("")

			await alert.show("Success", "Password changed successfully!")
		},
		async onError(error) {
			console.error(error)
			await alert.show("Error", "Failed to change password. Please try again later.")
		},
	})
	const deleteAccount = query.user.delete.useMutation({
		async onSuccess(response) {
			if (response.status === "invalid-password") {
				await alert.show("Error", "Invalid password.")
				return
			}
			await user.reload()
			await alert.show("Success", "Account deleted successfully!")
		},
		async onError(error) {
			console.error(error)
			await alert.show("Error", "Failed to delete account. Please try again later.")
		},
	})

	return (
		<Layout>
			<PageTitle>Settings</PageTitle>

			<Groups>
				<Group
					title="Personal information"
					onSubmit={() => {
						if (firstName.trim() === "" || lastName.trim() === "") {
							alert.show("Error", "Please fill in all fields.")
							return
						}
						personalInfo.mutate({ firstName, lastName })
					}}
					gridArea="personalInfo"
				>
					<InputField label="E-mail address" value={user.user!.email} disabled />
					<Row>
						<InputField
							label="First name"
							value={firstName}
							onChange={setFirstName}
							autoComplete="given-name"
						/>
						<InputField
							label="Last name"
							value={lastName}
							onChange={setLastName}
							autoComplete="family-name"
						/>
						<SubmitButton variant="primary" isLoading={personalInfo.isLoading}>
							Save changes
						</SubmitButton>
					</Row>
				</Group>

				<Group
					horizontal
					title="Change your password"
					onSubmit={async () => {
						if (currentPassword === "" || newPassword === "") {
							alert.show("Error", "Please fill in all fields.")
							return
						}
						if (newPassword !== newPasswordConfirmation) {
							alert.show("Error", "Passwords don't match.")
							return
						}
						changePassword.mutate({ current: currentPassword, new: newPassword })
					}}
					gridArea="password"
				>
					<InputField
						inputType="password"
						label="Current password"
						value={currentPassword}
						onChange={setCurrentPassword}
						autoComplete="current-password"
					/>
					<InputField
						inputType="password"
						label="New password"
						value={newPassword}
						onChange={setNewPassword}
						autoComplete="new-password"
					/>
					<InputField
						inputType="password"
						label="Confirm new password"
						value={newPasswordConfirmation}
						onChange={setNewPasswordConfirmation}
						autoComplete="new-password"
					/>
					<SubmitButton variant="primary" isLoading={changePassword.isLoading}>
						Change password
					</SubmitButton>
				</Group>

				{user.user!.communityProfileId !== null && (
					<Group title="Community avatar" gridArea="avatar">
						<ProfileAvatar />
					</Group>
				)}

				{!user.user!.isCreator && (
					<Group
						horizontal
						title="Delete account"
						onSubmit={async () => {
							if (deleteAccountPassword.length === 0) return

							const dialog = await alert.confirm(
								"Delete account",
								"Are you sure you want to delete your account? This action is irreversible.",
							)
							if (!dialog.result) return

							await deleteAccount.mutateAsync({ password: deleteAccountPassword })
							dialog.close()
						}}
						gridArea="deleteAccount"
					>
						<InputField
							inputType="password"
							label="Current password"
							value={deleteAccountPassword}
							onChange={setDeleteAccountPassword}
							autoComplete="current-password"
						/>
						<SubmitButton
							variant="danger"
							isDisabled={deleteAccountPassword.length === 0}
							isLoading={deleteAccount.isLoading}
						>
							Delete account
						</SubmitButton>
					</Group>
				)}
			</Groups>
		</Layout>
	)
}

const Groups = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	@media (min-width: 1000px) {
		display: grid;
		grid-template-areas: "personalInfo personalInfo" "password password" "avatar deleteAccount";
		flex-direction: column;
		gap: 42px;
		padding: 0;
	}
`

const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;

	@media (min-width: 1000px) {
		flex-wrap: wrap;
	}
`

export default SettingsPage
