import { useState } from "react"
import styled, { css } from "styled-components"
import tinycolor from "tinycolor2"
import Values from "values.js"

import { toPriceString } from "@forento/shared/utilities/currency"

import Button from "~/themes/original/components/Button"
import Card from "~/themes/original/components/Card"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const CoursesPage: React.FC = () => {
	const tags = query.course.listTags.useQuery()
	const courses = query.course.listPersonalized.useQuery()

	const [selectedTag, setSelectedTag] = useState<number>()

	return (
		<Layout>
			{tags.error || courses.error ? (
				<p>Failed to load courses.</p>
			) : courses.data === undefined || tags.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					{tags.data.length > 0 && (
						<Tags>
							{tags.data.map(tag => (
								<Tag
									key={tag.id}
									$isSelected={selectedTag === tag.id}
									onClick={() => setSelectedTag(current => (current !== tag.id ? tag.id : undefined))}
								>
									{tag.label}
								</Tag>
							))}
							{selectedTag !== undefined && (
								<Tag $isSelected onClick={() => setSelectedTag(undefined)}>
									Clear filter
								</Tag>
							)}
						</Tags>
					)}
					<Courses>
						{(selectedTag !== undefined
							? courses.data.filter(course => course.tags.some(tag => tag.id === selectedTag))
							: courses.data
						).map(course => (
							<Card
								key={course.id}
								title={course.title}
								description={course.shortDescription}
								thumbnailFilePath={course.thumbnailFilePath}
								onClick={routes.course.detail(course.id)}
								sticker={
									course.status !== "unlocked" || course.progress === 0
										? { text: "Not Started", color: "orange" }
										: course.progress < 1
											? { text: "Started", color: "blue" }
											: { text: "Completed", color: "green" }
								}
								progress={course.status === "unlocked" ? course.progress : undefined}
								footer={[
									{ text: `${course.pageCount} ${course.pageCount === 1 ? "page" : "pages"}` },
									{
										text: course.price
											? toPriceString(course.price)
											: course.membershipIds.length > 0
												? "Membership"
												: "Free",
										isBold: true,
									},
								]}
							/>
						))}
					</Courses>
				</>
			)}
		</Layout>
	)
}

const Tags = styled.div`
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
	margin-bottom: 16px;

	@media (max-width: 999px) {
		gap: 8px;
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Tag = styled(Button)<{ $isSelected: boolean }>(props => {
	const color = props.$isSelected
		? props.theme.primaryColor
		: new Values(props.theme.primaryColor).tint(50).hexString()

	return css`
		padding: 12px 24px;
		border-radius: 40px;
		background-color: ${color};
		color: ${tinycolor(color).isDark() ? "white" : "black"};
		line-height: 1;
	`
})

const Courses = styled.div`
	display: flex;
	gap: 32px;
	flex-wrap: wrap;
`

export default CoursesPage
