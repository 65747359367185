import { type FC } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { type EventResponse } from "@forento/shared/models/event"
import { toDateTimeString } from "@forento/shared/utilities/date"
import { parseNumber } from "@forento/shared/utilities/number"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import PurchaseItem from "~/themes/original/components/PurchaseItem"
import { toRelativeDateString } from "~/themes/original/utilities/date"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import trpc, { query } from "~/utilities/trpc"

import AddToCalendar from "./AddToCalendar"

const EventPage: FC = () => {
	const eventId = parseNumber(useParams().eventId ?? "")
	const user = useUser()
	const navigate = useNavigate()

	const { data: event, error, refetch } = query.event.getPersonalized.useQuery(eventId ?? -1)

	if (event === null) return <Navigate to={routes.event.index()} />

	async function handleRespond(response: EventResponse | null) {
		if (eventId === null) return
		if (user.user === null) {
			return navigate(`${routes.user.signup()}?next=${encodeURIComponent(location.pathname)}`)
		}

		await trpc.event.setResponse.mutate({ eventId, response })
		await refetch()
	}

	return (
		<PurchaseSuccessModalContextProvider
			content={{
				type: "event",
				isUnlocked: event?.isUnlocked ?? false,
				payPalOrderId: event?.isUnlocked && event.order?.type === "paypal" ? event.order.id : null,
			}}
		>
			<Layout>
				{error ? (
					<p>Failed to load event.</p>
				) : event === undefined ? (
					<PartialLoadingPage />
				) : (
					<Container>
						<Content>
							<Details>
								<Title>{event.title}</Title>
								{event.longDescription && (
									<Description dangerouslySetInnerHTML={{ __html: event.longDescription.html }} />
								)}
								<DateTime>
									{toDateTimeString(event.startDate)} ({toRelativeDateString(event.startDate)})
								</DateTime>
								{!event.isUnlocked ? (
									<PurchaseItem
										item={{ id: event.id, price: event.price, type: "event" }}
										onPurchase={refetch}
									/>
								) : event.response === "attending" ? (
									<>
										<Actions>
											{event.videoCallLink !== null && (
												<Button variant="primary" onClick={event.videoCallLink} newTab>
													Enter event
												</Button>
											)}
											<Button
												key="cancel"
												variant="danger-bordered"
												onClick={() => handleRespond(null)}
											>
												Cancel attendance
											</Button>
										</Actions>
										<AddToCalendar event={event} />
									</>
								) : (
									<Actions>
										<Button
											key="attend"
											variant="primary"
											onClick={() => handleRespond("attending")}
										>
											Attend event
										</Button>
										{event.response !== "interested" && (
											<Button
												key="interested"
												variant="secondary"
												onClick={() => handleRespond("interested")}
											>
												I'm interested
											</Button>
										)}
										{event.response !== "not-interested" && (
											<Button
												key="not-interested"
												variant="secondary"
												onClick={() => handleRespond("not-interested")}
											>
												I'm not interested
											</Button>
										)}
									</Actions>
								)}
							</Details>
							<ThumbnailContainer>
								<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={16 / 12}>
									{event.thumbnailFilePath ? <Thumbnail src={event.thumbnailFilePath} /> : <div />}
								</AspectRatio>
							</ThumbnailContainer>
						</Content>
					</Container>
				)}
			</Layout>
		</PurchaseSuccessModalContextProvider>
	)
}

const Container = styled.div`
	@media (max-width: 999px) {
		margin: 0 16px;
	}
`

const Content = styled.div`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 16px;
	display: flex;
	padding: 42px;
	gap: ${42 * 2}px;
	margin-bottom: 42px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 22px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
`

const Description = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
`

const ThumbnailContainer = styled.div`
	flex: 1 0 0;
	border-radius: 8px;
	overflow: hidden;

	@media (max-width: 999px) {
		display: none;
	}
`

const Thumbnail = styled.img.attrs({ alt: "Course thumbnail" })`
	object-fit: cover;
`

const DateTime = styled.time`
	margin-bottom: 16px;
`

const Actions = styled.div`
	display: flex;
	gap: 16px;
`

export default EventPage
