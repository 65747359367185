import Code from "@editorjs/code"
import Delimiter from "@editorjs/delimiter"
import EditorJs, { type OutputData, type API } from "@editorjs/editorjs"
import Embed from "@editorjs/embed"
import Header from "@editorjs/header"
import InlineCode from "@editorjs/inline-code"
import Link from "@editorjs/link"
import List from "@editorjs/list"
import Marker from "@editorjs/marker"
import Paragraph from "@editorjs/paragraph"
import SimpleImage from "@editorjs/simple-image"
import Table from "@editorjs/table"

import EmbedYoutube from "./EmbedYoutube"

type Args = {
	holder: HTMLElement | string
	data?: OutputData
	onChange?(api: API): void
	placeholder?: string
	readOnly?: boolean
}
export default function editorJs(args: Args) {
	return new EditorJs({
		holder: args.holder,
		data: args.data,
		onChange: args.onChange,
		readOnly: args.readOnly,
		placeholder: args.placeholder,
		minHeight: 0,
		tools: {
			// @ts-expect-error The types are fucked up
			embed: { class: Embed, inlineToolbar: true },
			// @ts-expect-error The types are fucked up
			table: { class: Table, inlineToolbar: true },
			// @ts-expect-error The types are fucked up
			paragraph: { class: Paragraph, inlineToolbar: true },
			// @ts-expect-error The types are fucked up
			list: { class: List, inlineToolbar: true },
			code: { class: Code, inlineToolbar: true },
			linkTool: { class: Link, inlineToolbar: true, config: { endpoint: "/api/editorjs-link" } },
			// @ts-expect-error The types are fucked up
			header: { class: Header, inlineToolbar: true },
			marker: { class: Marker, inlineToolbar: true },
			delimiter: { class: Delimiter, inlineToolbar: true },
			inlineCode: { class: InlineCode, inlineToolbar: true },
			simpleImage: { class: SimpleImage, inlineToolbar: true },
			youtube: { class: EmbedYoutube },
		},
	})
}
