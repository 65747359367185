import { toDateTimeString } from "@forento/shared/utilities/date"

import Card, { Cards } from "~/themes/original/components/Card"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { routes } from "~/themes/original/pages"
import { toRelativeDateString } from "~/themes/original/utilities/date"
import { query } from "~/utilities/trpc"

const EventsPage: React.FC = () => {
	const { data: upcomingEvents, error } = query.event.listPublic.useQuery()

	return (
		<Layout>
			{error ? (
				<p>Failed to load upcoming events.</p>
			) : upcomingEvents === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{upcomingEvents.map(event => (
						<Card
							key={event.id}
							onClick={routes.event.detail(event.id)}
							title={event.title}
							thumbnailFilePath={event.thumbnailFilePath}
							description={event.shortDescription ?? undefined}
							sticker={[
								{ text: toDateTimeString(event.startDate), color: "blue" },
								{ text: toRelativeDateString(event.startDate), color: "orange" },
							]}
						/>
					))}
				</Cards>
			)}
		</Layout>
	)
}

export default EventsPage
