import { createTRPCProxyClient, httpBatchLink, httpLink } from "@trpc/client"
import { createTRPCReact } from "@trpc/react-query"

import trpcTransformer from "@forento/shared/utilities/trpcTransformer"

import type { AppRoutes } from "../../../api/src/routes"

export const trpcOptions = {
	links: [CONFIG.isDevelopment ? httpLink({ url: "/api/_" }) : httpBatchLink({ url: "/api/_" })],
	transformer: trpcTransformer,
}

const trpc = createTRPCProxyClient<AppRoutes>(trpcOptions)

export const query = createTRPCReact<AppRoutes>()

export const queryTrpcClient = query.createClient(trpcOptions)

export default trpc
