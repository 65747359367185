import { type FC, useState } from "react"
import styled, { css } from "styled-components"
import tinycolor from "tinycolor2"
import Values from "values.js"

import { toPriceString } from "@forento/shared/utilities/currency"

import Button from "~/themes/original/components/Button"
import Card, { Cards } from "~/themes/original/components/Card"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const VideosPage: FC = () => {
	const tags = query.video.listTags.useQuery()
	const videos = query.video.listPersonalized.useQuery()

	const [selectedTag, setSelectedTag] = useState<number>()

	return (
		<Layout>
			{tags.error || videos.error ? (
				<p>Failed to load videos.</p>
			) : tags.data === undefined || videos.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					{tags.data.length > 0 && (
						<Tags>
							{tags.data.map(tag => (
								<Tag
									key={tag.id}
									$isSelected={selectedTag === tag.id}
									onClick={() => setSelectedTag(current => (current !== tag.id ? tag.id : undefined))}
								>
									{tag.label}
								</Tag>
							))}
							{selectedTag !== undefined && (
								<Tag $isSelected onClick={() => setSelectedTag(undefined)}>
									Clear filter
								</Tag>
							)}
						</Tags>
					)}
					<Cards>
						{(selectedTag !== undefined
							? videos.data.filter(video => video.tags.some(tag => tag.id === selectedTag))
							: videos.data
						).map(video => (
							<Card
								key={video.id}
								title={video.title}
								description={video.shortDescription ?? undefined}
								thumbnailFilePath={video.thumbnailFilePath}
								sticker={{
									text: video.price
										? toPriceString(video.price)
										: video.membershipIds.length > 0
											? "Membership"
											: "Free",
									color: "orange",
								}}
								onClick={routes.video.detail(video.id)}
							/>
						))}
					</Cards>
				</>
			)}
		</Layout>
	)
}

const Tags = styled.div`
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
	margin-bottom: 16px;

	@media (max-width: 999px) {
		gap: 8px;
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Tag = styled(Button)<{ $isSelected: boolean }>(props => {
	const color = props.$isSelected
		? props.theme.primaryColor
		: new Values(props.theme.primaryColor).tint(50).hexString()

	return css`
		padding: 12px 24px;
		border-radius: 40px;
		background-color: ${color};
		color: ${tinycolor(color).isDark() ? "white" : "black"};
		line-height: 1;
	`
})

export default VideosPage
