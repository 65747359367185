import { useState, type FC } from "react"
import { Trans } from "react-i18next"
import { Navigate, useParams } from "react-router"
import styled, { css } from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import Tooltip from "@forento/shared/components/Tooltip"
import VideoPlayer from "@forento/shared/components/VideoPlayer"
import { type DetailedCoursePage } from "@forento/shared/models/course"
import { toDateTimeString, toRelativeDateString } from "@forento/shared/utilities/date"
import { parseNumber } from "@forento/shared/utilities/number"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import { useCoursePlayer } from "~/api/course"
import { usePlatform } from "~/contexts/PlatformContext"
import useEditorJsDisplay from "~/hooks/useEditorJsDisplay"
import Button from "~/themes/school/components/Button"
import CourseComments from "~/themes/school/components/CourseComments"
import FullLoadingPage from "~/themes/school/components/FullLoadingPage"
import { ArrowIcon, SkipIcon } from "~/themes/school/components/Icon"
import Layout from "~/themes/school/components/Layout"
import { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import Resources from "~/themes/school/components/Resources"
import { ControlledTabs } from "~/themes/school/components/Tabs"
import { fonts } from "~/themes/school/styles"
import { useLocale, useTranslation } from "~/translations"
import routes from "~/utilities/routes"

import Attachments from "./Attachments"
import CertificateImage from "./CertificateImage"
import ChaptersList from "./ChaptersList"
import InputAnswerType from "./answers/InputAnswerType"
import MultiAnswerType from "./answers/MultiAnswerType"
import SingleAnswerType from "./answers/SingleAnswerType"
import background from "./background.svg"

type LoadedCoursePlayer = Extract<ReturnType<typeof useCoursePlayer>, { status: "success" }>

const CoursePlayerPage: FC = () => {
	const courseId = parseNumber(useParams().courseId!)
	const t = useTranslation()

	const player = useCoursePlayer(courseId ?? -1)

	if (player.status === "not-found") return <Navigate to={routes.course.index()} />

	return (
		<Layout>
			<PageBreadcrumb
				path={[{ title: t("course.title"), link: routes.course.index() }]}
				title={player.course?.title ?? "..."}
			/>
			{player.status === "error" ? (
				t("failedToLoad")
			) : player.status === "loading" ? (
				<FullLoadingPage />
			) : (
				<Content player={player} />
			)}
		</Layout>
	)
}

const Content: FC<{ player: LoadedCoursePlayer }> = ({ player }) => {
	const platform = usePlatform().platform
	const t = useTranslation()
	const locale = useLocale()

	const [isCertificateShown, setCertificateShown] = useState(false)

	const tabs = [
		{
			title: t("course.chapters"),
			content: (
				<ChaptersList
					courseId={player.course.id}
					currentPageId={player.current?.status === "unlocked" ? player.current.page.id : null}
					firstUncompletedPageId={player.course.firstUncompletedPage?.id ?? null}
					setPage={player.setPageById}
				/>
			),
		},
		{
			title: t("course.comments"),
			content: (
				<CourseComments
					courseId={player.course.id}
					pageId={player.current?.status === "unlocked" ? player.current.page.id : null}
					commentsUnderReview={player.course.commentsUnderReview}
					reloadCourse={player.reloadCourse}
				/>
			),
		},
	]
	if (player.course.resources.length > 0) {
		tabs.push({ title: t("course.resources"), content: <Resources resources={player.course.resources} /> })
	}

	return (
		<>
			<PageContent>
				<Spacer />
				{player.current === null ? (
					platform.content.certificates && player.course.issueCertificates && !isCertificateShown ? (
						<>
							<CertificateImage />
							<CourseCompletedTitle>{t("certificate.received")}</CourseCompletedTitle>
							<Buttons>
								<StyledButton variant="secondary" onClick={player.previousPage}>
									{t("course.previous")}
								</StyledButton>
								<StyledButton variant="secondary" onClick={routes.certificate.index()}>
									{t("certificate.goToAll")}
								</StyledButton>
								<StyledButton variant="primary" onClick={() => setCertificateShown(true)}>
									{t("course.continue")}
								</StyledButton>
							</Buttons>
						</>
					) : (
						<>
							<CourseCompletedTitle>{t("course.completed")}</CourseCompletedTitle>
							<Buttons>
								<StyledButton variant="secondary" onClick={player.previousPage}>
									{t("course.previous")}
								</StyledButton>
								<StyledButton variant="primary" onClick={routes.course.index()}>
									{t("course.goToAll")}
								</StyledButton>
							</Buttons>
						</>
					)
				) : player.current.status === "locked" ? (
					<>
						<CourseCompletedTitle>{t("course.pageLocked")}</CourseCompletedTitle>
						<Buttons>
							<StyledButton variant="secondary" onClick={player.previousPage}>
								{t("course.previous")}
							</StyledButton>
							<StyledButton variant="primary" onClick={routes.course.index()}>
								{t("course.goToAll")}
							</StyledButton>
						</Buttons>
					</>
				) : player.current.status === "not-dripped" ? (
					<>
						<PageTitle>{t("course.chapterNotDripped")}</PageTitle>
						<PageText>
							<Trans
								i18nKey="course.chapterNotDrippedText"
								values={{ relativeDate: toRelativeDateString(player.current.dripDate, locale) }}
								components={{
									tooltip: (
										<DripDateTooltip inline tooltip={toDateTimeString(player.current.dripDate)} />
									),
								}}
							/>
						</PageText>
						<Buttons>
							<StyledButton variant="secondary" onClick={player.previousPage}>
								{t("course.previous")}
							</StyledButton>
							<StyledButton variant="primary" onClick={routes.course.index()}>
								{t("course.goToAll")}
							</StyledButton>
						</Buttons>
					</>
				) : player.current.page.type === "custom" ? (
					<CustomPage
						page={player.current.page}
						isNextPageEnabled={player.isNextPageEnabled}
						onNextPage={player.nextPage}
						onPreviousPage={player.previousPage}
						onSkipChapter={player.skipChapter}
					/>
				) : player.current.page.type === "youtube" ? (
					<>
						<VideoContainer>
							<AspectRatio width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9}>
								<iframe
									width="100%"
									height="100%"
									style={{ border: "none" }}
									src={`https://www.youtube-nocookie.com/embed/${player.current.page.youTubeVideoId}`}
									title="YouTube video player"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									referrerPolicy="strict-origin-when-cross-origin"
									allowFullScreen
								/>
							</AspectRatio>
						</VideoContainer>
						<PageTitle>{player.current.page.title}</PageTitle>
						{player.current.page.htmlContent !== null && (
							<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
						)}
						<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
						<Buttons>
							<PreviousButton variant="secondary" onClick={player.previousPage}>
								{t("course.previous")}
							</PreviousButton>
							<NextButton variant="primary" onClick={player.nextPage}>
								{t("course.continue")}
							</NextButton>
							<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
								{t("course.skipChapter")}
							</SkipChapterButton>
						</Buttons>
					</>
				) : player.current.page.type === "vimeo" ? (
					<>
						<VideoContainer>
							<AspectRatio width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9}>
								<iframe
									width="100%"
									height="100%"
									style={{ border: "none" }}
									src={`https://player.vimeo.com/video/${player.current.page.vimeoVideoId}?h=fa26a9e8f5&loop=1&title=0&byline=0&portrait=0&badge=0`}
									allow="autoplay; fullscreen; picture-in-picture"
									allowFullScreen
								/>
							</AspectRatio>
						</VideoContainer>
						<PageTitle>{player.current.page.title}</PageTitle>
						{player.current.page.htmlContent !== null && (
							<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
						)}
						<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
						<Buttons>
							<PreviousButton variant="secondary" onClick={player.previousPage}>
								{t("course.previous")}
							</PreviousButton>
							<NextButton variant="primary" onClick={player.nextPage}>
								{t("course.continue")}
							</NextButton>
							<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
								{t("course.skipChapter")}
							</SkipChapterButton>
						</Buttons>
					</>
				) : player.current.page.type === "video" ? (
					<>
						<VideoContainer>
							<VideoPlayer video={player.current.page.video} />
						</VideoContainer>
						<PageTitle>{player.current.page.title}</PageTitle>
						{player.current.page.htmlContent !== null && (
							<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
						)}
						<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
						<Buttons>
							<PreviousButton variant="secondary" onClick={player.previousPage}>
								{t("course.previous")}
							</PreviousButton>
							<NextButton variant="primary" onClick={player.nextPage}>
								{t("course.continue")}
							</NextButton>
							<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
								{t("course.skipChapter")}
							</SkipChapterButton>
						</Buttons>
					</>
				) : player.current.page.type === "input" ? (
					<>
						{player.current.page.image !== null && (
							<PageImage
								src={player.current.page.image.filePath}
								$fit={player.current.page.image.contain ? "contain" : "cover"}
							/>
						)}
						<PageTitle>{player.current.page.title}</PageTitle>
						{player.current.page.htmlContent !== null && (
							<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
						)}
						<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
						<Answers>
							<InputAnswerType
								key={player.current.page.id}
								inputType={player.current.page.inputType}
								onChange={player.setSelection}
							/>
						</Answers>
						<Buttons>
							<PreviousButton variant="secondary" onClick={player.previousPage}>
								<ButtonIcon as={ArrowIcon} direction="left" />
								{t("course.previous")}
							</PreviousButton>
							<NextButton
								variant="primary"
								onClick={player.nextPage}
								isDisabled={!player.isNextPageEnabled}
							>
								<ButtonIcon as={ArrowIcon} />
								{t("course.continue")}
							</NextButton>
							<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
								<ButtonIcon as={SkipIcon} />
								{t("course.skipChapter")}
							</SkipChapterButton>
						</Buttons>
					</>
				) : player.current.page.type === "quiz" ? (
					<>
						{player.current.page.image !== null && (
							<PageImage
								src={player.current.page.image.filePath}
								$fit={player.current.page.image.contain ? "contain" : "cover"}
							/>
						)}
						<PageTitle>{player.current.page.title}</PageTitle>
						{player.current.page.htmlContent !== null && (
							<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
						)}
						<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
						<Answers>
							{player.current.page.quiz.type === "single-answer" ? (
								<SingleAnswerType
									answers={player.current.page.quiz.answers}
									setSelection={player.setSelection}
								/>
							) : player.current.page.quiz.type === "multi-answer" ? (
								<MultiAnswerType
									answers={player.current.page.quiz.answers}
									setSelection={player.setSelection}
								/>
							) : (
								exhaustiveGuard(player.current.page.quiz.type)
							)}
						</Answers>
						<Buttons>
							<PreviousButton variant="secondary" onClick={player.previousPage}>
								<ButtonIcon as={ArrowIcon} direction="left" />
								{t("course.previous")}
							</PreviousButton>
							<NextButton
								variant="primary"
								onClick={player.nextPage}
								isDisabled={!player.isNextPageEnabled}
							>
								<ButtonIcon as={ArrowIcon} />
								{t("course.continue")}
							</NextButton>
							<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
								<ButtonIcon as={SkipIcon} />
								{t("course.skipChapter")}
							</SkipChapterButton>
						</Buttons>
					</>
				) : player.current.page.type === "text" ? (
					<>
						{player.current.page.image !== null && (
							<PageImage
								src={player.current.page.image.filePath}
								$fit={player.current.page.image.contain ? "contain" : "cover"}
							/>
						)}
						<PageTitle>{player.current.page.title}</PageTitle>
						{player.current.page.htmlContent !== null && (
							<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
						)}
						<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
						<Buttons>
							<PreviousButton variant="secondary" onClick={player.previousPage}>
								<ButtonIcon as={ArrowIcon} direction="left" />
								{t("course.previous")}
							</PreviousButton>
							<NextButton
								variant="primary"
								onClick={player.nextPage}
								isDisabled={!player.isNextPageEnabled}
							>
								<ButtonIcon as={ArrowIcon} />
								{t("course.continue")}
							</NextButton>
							<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
								<ButtonIcon as={SkipIcon} />
								{t("course.skipChapter")}
							</SkipChapterButton>
						</Buttons>
					</>
				) : (
					exhaustiveGuard(player.current.page)
				)}
				<Spacer />
				<ScrollIndicator>
					<ScrollIndicatorIcon />
					{player.course.resources.length > 0
						? t("course.scrollDown.chapterCommentsResources")
						: t("course.scrollDown.chaptersComments")}
				</ScrollIndicator>
			</PageContent>
			<Details>
				<ControlledTabs tabs={tabs} />
			</Details>
		</>
	)
}

type CustomPageProps = {
	page: Extract<DetailedCoursePage, { type: "custom" }>
	isNextPageEnabled: boolean
	onPreviousPage(): void
	onNextPage(): void
	onSkipChapter(): void
}
const CustomPage: FC<CustomPageProps> = ({ page, isNextPageEnabled, onPreviousPage, onNextPage, onSkipChapter }) => {
	const [editorElement, setEditorElement] = useState<HTMLDivElement | null>(null)

	useEditorJsDisplay(editorElement, page.editorjsContent)

	return (
		<>
			<PageTitle>{page.title}</PageTitle>
			<PageEditorJs ref={setEditorElement} />
			<Attachments links={page.links} attachments={page.attachments} />
			<Buttons>
				<PreviousButton variant="secondary" onClick={onPreviousPage}>
					Previous
				</PreviousButton>
				<NextButton variant="primary" onClick={onNextPage} isDisabled={!isNextPageEnabled}>
					Continue
				</NextButton>
				<SkipChapterButton variant="secondary" onClick={onSkipChapter}>
					Skip chapter
				</SkipChapterButton>
			</Buttons>
		</>
	)
}

const PageContent = styled.div`
	background-color: white;
	background-image: url(${background});
	background-size: cover;
	background-position: center center;
	padding: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;

	@media (min-width: 1000px) {
		min-height: calc(100vh - ${42 + 28 + 32 + 42}px);
		padding: 42px;
		margin-bottom: 42px;
	}
`

const VideoContainer = styled.div`
	width: 100%;
	max-width: 800px;
	margin-bottom: 24px;
`

const PageImage = styled.img.attrs({ alt: "Page illustration" })<{ $fit: "cover" | "contain" }>`
	width: 100%;
	max-width: 500px;
	border-radius: 8px;
	margin-bottom: 24px;

	${props =>
		props.$fit === "cover" &&
		css`
			aspect-ratio: 3;
			object-fit: cover;
		`}
`

const PageTitle = styled.h2`
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 24px;
`

const CourseCompletedTitle = styled(PageTitle)`
	margin-bottom: 24px;
`

const PageHtmlText = styled.div`
	max-width: 500px;
	font-size: 14px;
	text-align: left;
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;

	* {
		text-align: left !important;
	}

	p,
	ul,
	ol {
		&:not(:last-child) {
			margin-bottom: 14px;
		}
	}

	h2 {
		font-size: 20px;

		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}
`

const PageEditorJs = styled.div`
	width: 100%;
	max-width: 650px;
	font-size: 14px;
	margin-bottom: 24px;
`

const PageText = styled.div`
	max-width: 500px;
	font-size: 14px;
	text-align: center;
	margin-bottom: 24px;
`

const DripDateTooltip = styled(Tooltip)`
	text-decoration: underline dotted;
`

const Answers = styled.div`
	width: 100%;
	max-width: 500px;
	margin-bottom: 32px;
`

const Buttons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	@media (min-width: 1000px) {
		display: grid;
		grid-template-areas: "previous next skipChapter";
		gap: 16px;
	}
`

const StyledButton = styled(Button)`
	min-width: 150px;

	@media (max-width: 999px) {
		min-width: 0;
		flex: 1;
	}
`

const ButtonIcon = styled.div`
	width: 14px;
	height: 14px;
`

const PreviousButton = styled(StyledButton).attrs({ variant: "secondary" })`
	grid-area: previous;
`

const NextButton = styled(StyledButton).attrs({ variant: "primary" })`
	grid-area: next;
`

const SkipChapterButton = styled(StyledButton).attrs({ variant: "secondary" })`
	grid-area: skipChapter;
`

const Spacer = styled.div`
	flex: 1 0 0;
`

const ScrollIndicator = styled.div`
	font-size: 18px;
	text-align: center;
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 32px;

	@media (max-width: 999px) {
		display: none;
	}
`

const ScrollIndicatorIcon = styled(ArrowIcon).attrs({ direction: "down" })`
	width: 24px;
	height: 24px;
`

const Details = styled.div`
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 32px;

	@media (min-width: 1000px) {
		padding: 48px 86px;
	}
`

export default CoursePlayerPage
