import { type FC } from "react"

import { toPriceString } from "@forento/shared/utilities/currency"

import Card, { Cards } from "~/themes/original/components/Card"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const DownloadablesPage: FC = () => {
	const { data: downloadables, error } = query.downloadable.listPersonalized.useQuery()

	return (
		<Layout>
			{error ? (
				<p>Failed to load downloadables.</p>
			) : downloadables === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{downloadables.map(downloadable => (
						<Card
							key={downloadable.id}
							title={downloadable.title}
							description={downloadable.shortDescription ?? undefined}
							thumbnailFilePath={downloadable.thumbnailFilePath}
							sticker={
								downloadable.price
									? { text: toPriceString(downloadable.price), color: "orange" }
									: downloadable.membershipIds.length > 0
										? { text: "Membership", color: "orange" }
										: { text: "Free", color: "green" }
							}
							onClick={routes.downloadable.detail(downloadable.id)}
						/>
					))}
				</Cards>
			)}
		</Layout>
	)
}

export default DownloadablesPage
