import { useState } from "react"
import { Navigate, useSearchParams } from "react-router"
import styled from "styled-components"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import Button from "~/themes/original/components/Button"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { getHomeRoute } from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

import Layout, { Title, NavigationLinks, NavigationLink, NavigationLinkButton, Description } from "./Layout"

const InvitedSignupMembership: React.FC = () => {
	const platform = usePlatform()
	const alert = useAlert()

	const inviteToken = useSearchParams()[0].get("invite")

	const [submittingPriceId, setSubmittingPriceId] = useState<number>()

	const { data: invite, error } = query.user.verifyStudentInviteToken.useQuery(inviteToken ?? "")

	if (inviteToken === null || error) return <Navigate to={getHomeRoute(platform.platform!)} />

	if (invite !== undefined) {
		if (invite.status !== "success" || invite.invite.membership === null) {
			return <Navigate to={getHomeRoute(platform.platform!)} />
		}
	}

	const handleStartMembership = async (priceId: number) => {
		if (invite === undefined) return

		setSubmittingPriceId(priceId)
		try {
			const result = await trpc.payment.joinMembershipPrice.mutate(priceId)
			if (result.status === "redirect") {
				window.location.href = result.url
			}
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to join membership. Please try again later.")
			setSubmittingPriceId(undefined)
		}
	}

	return (
		<Layout>
			<Title>Join membership</Title>

			{invite === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					<Description>
						You have been invited to join the membership {invite.invite.membership!.title}.
					</Description>
					<Plans>
						{invite.invite.membership!.prices.map(price => (
							<Button
								key={price.id}
								variant="primary"
								isLoading={submittingPriceId === price.id}
								isDisabled={submittingPriceId !== undefined}
								onClick={() => handleStartMembership(price.id)}
							>
								Join for {price.price} {price.currency}/{price.paymentInterval}
							</Button>
						))}
					</Plans>

					<NavigationLinks>
						<NavigationLink>
							Don't want to join this membership?
							<NavigationLinkButton onClick={getHomeRoute(platform.platform!)}>
								Skip this step
							</NavigationLinkButton>
						</NavigationLink>
					</NavigationLinks>
				</>
			)}
		</Layout>
	)
}

const Plans = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default InvitedSignupMembership
