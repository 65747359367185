import { query } from "~/utilities/trpc"

export default function useConversations(currentConversationId: number | null) {
	const list = query.community.listConversations.useQuery()
	const current = query.community.getConversation.useQuery(currentConversationId!, {
		enabled: currentConversationId !== null,
	})

	if (list.error || (currentConversationId !== null && current.error)) {
		return { status: "error" as const }
	}

	if (!list.data || (currentConversationId !== null && !current.data)) {
		return { status: "loading" as const }
	}

	return {
		status: "success" as const,
		list: list.data,
		current: current?.data ?? null,
		async reload() {
			await Promise.all([list.refetch(), current?.refetch()])
		},
	}
}
