import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import styled from "styled-components"

import { useCookiePolicy } from "~/api/cookiePolicy"
import Layout from "~/themes/original/components/Layout"

const CookiePolicyPage: React.FC = () => {
	const content = useCookiePolicy()

	return (
		<Layout>
			<Title>Cookie Policy</Title>
			<Content remarkPlugins={[remarkGfm]}>{content}</Content>
		</Layout>
	)
}

const Title = styled.h1`
	font-weight: 500;
	font-size: 36px;
	color: #151d48;
	margin-bottom: 24px;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Content = styled(Markdown)`
	h2 {
		font-weight: 600;
		font-size: 20px;
		color: #151d48;
		margin-bottom: 8px;
	}

	p {
		font-size: 14px;
		margin-bottom: 16px;
	}

	ul,
	ol {
		margin: 0 0 16px 16px;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #ddd;
		margin-bottom: 16px;
	}

	table th,
	table td {
		padding: 8px;
	}
`

export default CookiePolicyPage
