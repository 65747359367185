import { type FC, useState } from "react"
import { useDropzone } from "react-dropzone"
import styled, { css } from "styled-components"

import { getDataUrlByFile } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"

import Button, { SubmitButton } from "~/themes/original/components/Button"
import Dropdown from "~/themes/original/components/Dropdown"
import { DeleteIcon, UploadFileIcon } from "~/themes/original/components/Icon"
import InputField, { useRichTextArea } from "~/themes/original/components/InputField"
import Modal, { ModalButtons, ModalTitle } from "~/themes/original/components/Modal"
import { dangerColor } from "~/utilities/styles"
import trpc, { query } from "~/utilities/trpc"

type Props = { channel: { id: number | null; name: string }; onPostCreated(): void }
const CreatePost: FC<Props> = ({ channel, onPostCreated }) => {
	const categories = query.community.listPostCategories.useQuery()

	const [isOpen, setOpen] = useState(false)
	const [title, setTitle] = useState("")
	const content = useRichTextArea({ label: "Text" })
	const [categoryId, setCategoryId] = useState<string | null>(null)
	const [pollOptions, setPollOptions] = useState<string[]>([])
	const [attachments, setAttachments] = useState<{ label: string; file: File }[]>([])
	const [isSubmitting, setSubmitting] = useState(false)

	const dropzone = useDropzone({
		onDrop: async files => {
			for (const file of files) {
				const dialog = prompt("Add attachment", file.name)
				if (dialog === null) continue
				setAttachments(current => [...current, { label: dialog, file }])
			}
		},
	})

	const handleSubmit = async () => {
		const exportedContent = content.exportEditorState()
		if (title.trim().length === 0 || exportedContent === null) return

		setSubmitting(true)

		try {
			await trpc.community.createPost.mutate({
				channelId: channel.id,
				title: title.trim(),
				content: exportedContent,
				categoryId: parseNumber(categoryId),
				pollOptions,
				attachments: await Promise.all(
					attachments.map(async attachment => ({
						label: attachment.label,
						dataUrl: await getDataUrlByFile(attachment.file),
					})),
				),
			})

			setTitle("")
			content.set(null)
			setCategoryId(null)
			setPollOptions([])
			setAttachments([])
			setOpen(false)
			onPostCreated()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Container>
			<Button variant="primary" onClick={() => setOpen(true)}>
				Create a new post
			</Button>
			<Modal isOpen={isOpen} onSubmit={handleSubmit}>
				<ModalTitle>Create a new post</ModalTitle>
				<InputField label="Title" value={title} onChange={setTitle} />
				{content.element}
				<Dropdown
					items={[
						{ id: "none", title: "-- No category --" },
						...(categories.data?.map(category => ({
							id: category.id.toString(),
							title: category.label,
						})) ?? []),
						{ id: "create", title: "Create new category" },
					]}
					selectedItemId={categoryId ?? "none"}
					onChange={id => setCategoryId(id === "none" ? null : id)}
				/>
				<PollOptions>
					{pollOptions.map((option, index) => (
						<PollOption key={index}>
							<PollOptionInput
								value={option}
								onChange={value =>
									setPollOptions(current => [
										...current.slice(0, index),
										value,
										...current.slice(index + 1),
									])
								}
							/>
							<PollOptionDeleteButton
								onClick={() =>
									setPollOptions(current => [...current.slice(0, index), ...current.slice(index + 1)])
								}
							>
								<DeleteIcon />
							</PollOptionDeleteButton>
						</PollOption>
					))}
					<Button variant="secondary" onClick={() => setPollOptions(current => [...current, ""])}>
						Add poll option
					</Button>
				</PollOptions>
				<Attachments>
					{attachments.map((attachment, index) => (
						<Attachment key={index}>
							<DeleteAttachment
								onClick={() =>
									setAttachments([...attachments.slice(0, index), ...attachments.slice(index + 1)])
								}
							>
								<DeleteIcon />
							</DeleteAttachment>
							{attachment.label}
						</Attachment>
					))}
					<AddAttachment {...dropzone.getRootProps()}>
						<input {...dropzone.getInputProps()} />
						<AddAttachmentIcon />
					</AddAttachment>
				</Attachments>
				<ModalButtons>
					<Button variant="secondary" isDisabled={isSubmitting} onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<SubmitButton variant="primary" isLoading={isSubmitting}>
						Create post
					</SubmitButton>
				</ModalButtons>
			</Modal>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 32px;
`

const PollOptions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const PollOption = styled.div`
	display: flex;
	gap: 16px;
`

const PollOptionInput = styled(InputField)`
	flex: 1;
`

const PollOptionDeleteButton = styled(Button)`
	width: 52px;
	height: 52px;
	background-color: ${dangerColor};
	color: white;
	padding: 8px;
	border-radius: 4px;
`

const Attachments = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
`

const attachmentStyles = css`
	border: 1px dashed ${props => props.theme.primaryColor};
	border-radius: 8px;
	padding: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Attachment = styled.div`
	${attachmentStyles}
	word-break: break-all;
	position: relative;
`

const DeleteAttachment = styled(Button)`
	position: absolute;
	top: 4px;
	right: 4px;
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

const AddAttachment = styled.div`
	${attachmentStyles};
	cursor: pointer;
`

const AddAttachmentIcon = styled(UploadFileIcon)`
	width: 24px;
	height: 24px;
	color: ${props => props.theme.primaryColor};
`

export default CreatePost
