import { type FC } from "react"
import styled from "styled-components"

import Button from "~/themes/school/components/Button"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"

const Footer: FC = () => {
	const t = useTranslation()

	const navItems = [
		{ label: t("policy.termsOfService"), onClick: routes.policy.termsOfService() },
		{ label: t("policy.privacyPolicy"), onClick: routes.policy.privacy() },
		{ label: t("policy.cookiePolicy"), onClick: routes.policy.cookie() },
	]

	return (
		<Container>
			{navItems.map(item => (
				<NavItem key={item.label} onClick={item.onClick}>
					{item.label}
				</NavItem>
			))}
		</Container>
	)
}

const Container = styled.div`
	padding: 0 42px 42px;
	display: flex;
	align-items: center;
	gap: 16px;

	@media (max-width: 449px) {
		flex-direction: column;
	}
`

const NavItem = styled(Button)`
	font-size: 16px;
`

export default Footer
