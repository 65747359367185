import { useCallback, useEffect, useState } from "react"

import { type DetailedCoursePage, type PersonalizedCourse } from "@forento/shared/models/course"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import trpc, { query } from "~/utilities/trpc"

type CourseDetailButton =
	| { variant: "start" | "continue" | "membership" }
	| { variant: "purchase"; price: { amount: number; currency: string } }
export type CourseDetail =
	| { status: "error" | "loading" | "not-found" }
	| { status: "success"; title: string; button: CourseDetailButton; course: PersonalizedCourse }

type Current =
	| { status: "locked" }
	| { status: "not-dripped"; dripDate: Date; page: { id: number } }
	| { status: "unlocked"; page: DetailedCoursePage }

export function useCourseDetail(courseId: number): [CourseDetail, () => Promise<unknown>] {
	const platform = usePlatform()

	const { data: course, error, refetch } = query.course.getPersonalized.useQuery(courseId)

	if (error) return [{ status: "error" }, refetch]
	if (course === undefined) return [{ status: "loading" }, refetch]
	if (course === null) return [{ status: "not-found" }, refetch]

	const title =
		platform.platform !== undefined
			? `${course.title} - ${platform.platform.title ?? platform.platform.name}`
			: course.title

	const button: CourseDetailButton = (() => {
		switch (course.status) {
			case "unlocked":
				return course.progress > 0 ? { variant: "continue" } : { variant: "start" }
			case "paywall":
				return { variant: "purchase", price: course.price }
			case "membership-required":
				return { variant: "membership" }
		}
	})()

	return [{ status: "success", title, button, course }, refetch]
}

export function useCoursePlayer(courseId: number) {
	const user = useUser()
	const alert = useAlert()

	const [current, setCurrent] = useState<Current | null>()
	const [selection, setSelection] = useState<number | number[] | string>()

	const { data: course, error, refetch } = query.course.getPersonalized.useQuery(courseId)

	const isNextPageEnabled: boolean = (() => {
		if (current?.status !== "unlocked") return false
		if (current.page.type === "quiz") {
			if (current.page.quiz.type === "single-answer") return typeof selection === "number"
			else return Array.isArray(selection) && selection.length > 0
		}
		if (current.page.type === "input") {
			return typeof selection === "string" && selection.trim().length > 0
		}
		return true
	})()

	const loadPage = useCallback(
		async (page: Current | null) => {
			if (course == null || course.status !== "unlocked") return

			if (
				current?.status === "unlocked" &&
				page !== null &&
				course.firstUncompletedPage?.id === current.page.id
			) {
				await refetch()
			}

			setSelection(undefined)
			setCurrent(page)
		},
		[course, current, refetch],
	)

	const setPageById = useCallback(async (id: number) => {
		const page = await trpc.course.getPage.query(id)
		if (page === null) return

		setSelection(undefined)
		setCurrent(page)
	}, [])

	const previousPage = useCallback(async () => {
		if (course == null || current === undefined) return
		const previousPage = await (() => {
			if (current === null) return trpc.course.getFinalPageByCourseId.query(course.id)
			if (current.status === "locked") return trpc.course.getFirstUncompletedPageByCourseId.query(course.id)
			return trpc.course.getPreviousPageByPageId.query(current.page.id)
		})()
		if (previousPage === null) return

		setSelection(undefined)
		setCurrent(previousPage)
	}, [course, current])

	const nextPage = useCallback(async () => {
		if (!isNextPageEnabled || current?.status !== "unlocked") return

		const response = await trpc.course.completePage.mutate({
			id: current.page.id,
			selection: current.page.type === "quiz" || current.page.type === "input" ? selection : undefined,
		})
		if (response.status === "incorrect") {
			await alert.show("Incorrect answer!", "The answer you provided is incorrect.")
			return
		}
		loadPage(response.next)
	}, [isNextPageEnabled, current, selection, loadPage, alert])

	const skipChapter = useCallback(async () => {
		if (!isNextPageEnabled || current?.status !== "unlocked") return

		const result = await alert.confirm("Skip chapter", "Are you sure you want to skip this chapter?")
		if (!result.result) return

		await trpc.course.skipChapter.mutate(current.page.chapter.id)

		loadPage(await trpc.course.getNextChapterByPageId.query(current.page.id))
		result.close()
	}, [isNextPageEnabled, current, alert, loadPage])

	useEffect(() => {
		if (user.user && course && !current && course.status === "unlocked") {
			trpc.course.getFirstUncompletedPageByCourseId.query(course.id).then(loadPage)
		}
	}, [user.user, course, loadPage, current])

	if (error) return { status: "error" as const }
	if (course === undefined || current === undefined) return { status: "loading" as const }
	if (course === null) return { status: "not-found" as const }
	if (course.status !== "unlocked") return { status: "not-found" as const }

	return {
		status: "success" as const,
		course,
		reloadCourse: refetch,
		current,
		isNextPageEnabled,
		nextPage,
		previousPage,
		skipChapter,
		setPageById,
		setSelection,
	}
}
