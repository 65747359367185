import { useState, type FC } from "react"
import { useNavigate, useParams } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { parseNumber } from "@forento/shared/utilities/number"

import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import PriceButton from "~/themes/original/pages/membership/MembershipPage/PriceButton"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import trpc, { query } from "~/utilities/trpc"

const MembershipPage: FC = () => {
	const membershipId = parseNumber(useParams().membershipId)
	const user = useUser()
	const navigate = useNavigate()
	const alert = useAlert()

	const [submittingPriceId, setSubmittingPriceId] = useState<number>()

	const { data: membership, error } = query.membership.getPublic.useQuery(membershipId ?? -1)

	async function handleSelect(priceId: number) {
		if (!user.user) {
			return navigate(`${routes.user.signup()}?next=${encodeURIComponent(location.pathname)}`)
		}

		setSubmittingPriceId(priceId)
		try {
			const result = await trpc.payment.joinMembershipPrice.mutate(priceId)
			switch (result.status) {
				case "success":
					await user.reload()
					setSubmittingPriceId(undefined)
					break
				case "redirect":
					window.location.href = result.url
					break
			}
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to join membership. Please try again later.")
			setSubmittingPriceId(undefined)
		}
	}

	async function handleCancel() {
		const membership = user.user!.membership
		if (membership === null || membership.type === "external") return

		setSubmittingPriceId(membership.priceId)
		try {
			await trpc.payment.leaveMembership.mutate()
			await user.reload()
		} finally {
			setSubmittingPriceId(undefined)
		}
	}

	return (
		<Layout>
			{error ? (
				<p>Failed to load membership.</p>
			) : !membership ? (
				<PartialLoadingPage />
			) : (
				<Container>
					<Content>
						<Details>
							<Title>{membership.title}</Title>
							{membership.longDescription && (
								<Description dangerouslySetInnerHTML={{ __html: membership.longDescription.html }} />
							)}
							{user.user?.isCreator ? (
								<CreatorWarning>You cannot subscribe to a membership as a creator.</CreatorWarning>
							) : (
								<ButtonsRow>
									{membership.prices.map(price => (
										<PriceButton
											key={price.id}
											price={price}
											membership={membership}
											submittingPriceId={submittingPriceId ?? null}
											onSelect={() => handleSelect(price.id)}
											onCancel={handleCancel}
										/>
									))}
								</ButtonsRow>
							)}
						</Details>
						<ThumbnailContainer>
							<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={16 / 12}>
								{membership.thumbnailFilePath ? (
									<Thumbnail src={membership.thumbnailFilePath} />
								) : (
									<div />
								)}
							</AspectRatio>
						</ThumbnailContainer>
					</Content>
				</Container>
			)}
		</Layout>
	)
}

const Container = styled.div`
	@media (max-width: 999px) {
		margin: 0 16px;
	}
`

const Content = styled.div`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 16px;
	display: flex;
	padding: 42px;
	gap: ${42 * 2}px;
	margin-bottom: 42px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 22px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
`

const Description = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
`

const ThumbnailContainer = styled.div`
	flex: 1 0 0;
	border-radius: 8px;
	overflow: hidden;

	@media (max-width: 999px) {
		display: none;
	}
`

const Thumbnail = styled.img.attrs({ alt: "Course thumbnail" })`
	object-fit: cover;
`

const ButtonsRow = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const CreatorWarning = styled.p`
	font-size: 14px;
	font-style: italic;
	color: ${lightTextColor};
	text-align: center;
`

export default MembershipPage
